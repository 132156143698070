@font-face {
	font-family: 'icomoon';
	src: url('../fonts/sprite/icomoon.eot?43t99a');
	src: url('../fonts/sprite/icomoon.eot?43t99a#iefix') format('embedded-opentype'), url('../fonts/sprite/icomoon.ttf?43t99a') format('truetype'), url('../fonts/sprite/icomoon.woff?43t99a') format('woff'), url('../fonts/sprite/icomoon.svg?43t99a#icomoon') format('svg');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'Aeonik';
	src: url('../fonts/Aeonik-Medium-Web/Aeonik-Medium.woff2') format('woff2'), url('../fonts/Aeonik-Medium-Web/Aeonik-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap; }
