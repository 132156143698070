// Theme: Default
// Description: Original highlight.js style
// Author: (c) Ivan Sagalaev <maniac@softwaremaniacs.org>
// Maintainer: @highlightjs/core-team
// Website: https://highlightjs.org/
// License: see project LICENSE
// Touched: 2021


// This is left on purpose making default.css the single file that can be lifted
// as-is from the repository directly without the need for a build step

// Typically this "required" baseline CSS is added by `makestuff.js` during build.

pre code.hljs {
	display: block;

	overflow-x: auto;

	margin: -7px 0 -6px;
	padding: 0;

	font-size: 13px;
	line-height: 22px;
	letter-spacing: .02em; }

/* end baseline CSS */

.hljs {
	color: #f8f8f2; }

/* Base color: saturation 0 */

.hljs-subst {
	/* default */ }

/* purposely ignored */

.hljs-punctuation {
	color: #444a; }

.hljs-tag,
.hljs-subst {
	color: #f8f8f2; }

.hljs-tag .hljs-name,
.hljs-tag .hljs-attr {
	color: #444; }


.hljs-attribute,
.hljs-meta .hljs-keyword,
.hljs-doctag,
.hljs-name {
	font-weight: bold; }


/* User color: hue: 0 */

.hljs-template-tag {
	color: #880000; }

.hljs-section {
	font-weight: bold; }

.hljs-operator {
	color: #BC6060; }


/* Meta color: hue: 200 */

.hljs-meta .hljs-string {
	color: #4d99bf; }


/* Misc effects */

.hljs-emphasis {
	font-style: italic; }

.hljs-strong {
	font-weight: bold; }




.hljs-strong,
.hljs-emphasis {
	color: #a8a8a2; }

.hljs-bullet,
.hljs-quote,
.hljs-number,
.hljs-regexp,
.hljs-literal,
.hljs-link {
	color: #ae81ff; }

.hljs-code,
.hljs-title,
.hljs-section,
.hljs-selector-class {
	color: #a6e22e; }

.hljs-keyword,
.hljs-selector-tag,
.hljs-name,
.hljs-attr {
	color: #f92672; }

.hljs-symbol,
.hljs-attribute {
	color: #66d9ef; }

.hljs-params,
.hljs-title.class_,
.hljs-class .hljs-title {
	color: #f8f8f2; }

.hljs-string,
.hljs-type,
.hljs-built_in,
.hljs-selector-id,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-addition,
.hljs-variable,
.hljs-template-variable {
	color: #e6db74; }

.hljs-comment,
.hljs-deletion,
.hljs-meta {
	color: $brand-success; }
