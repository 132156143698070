.-ol-input,
.-ol-textarea {
	display: block;

	width: 100%;

	padding: $p_vert-input $p_horiz-input;
	border: $brd_w-input solid $brd_c-input;
	border-radius: $brd_rd-input;

	background: $bg-input;

	color: $c-input;
	font-size: $f_sz-input;
	line-height: $h-input - $brd_w-input * 2 - $p_vert-input * 2;
	letter-spacing: $l_s-input;

	text-overflow: ellipsis;

	@include placeholder-style;

	&[readonly],
	&[disabled] {
		color: $grey-light;
		cursor: default; } }

.-ol-input {
	height: $h-input; }

.-ol-textarea {
	height: $h-textarea;
	resize: vertical;
	overflow: auto; }

.-ol-input-file {
	position: relative;
	overflow: hidden;
	width: 100%;

	input {
		$h-input-file: 100%;

		width: 100%;
		height: $h-input-file;

		position: absolute;
		top: 0;
		left: 0;
		z-index: 3;

		opacity: 0;

		cursor: pointer; } }

.-ol-checkbox {
	position: relative;

	width: $w-checkbox;
	height: $h-checkbox;

	&__input {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;

		opacity: 0;
		overflow: hidden;

		width: 100%;
		height: 100%; }

	&__trigger {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		border: $brd_w-checkbox solid $brd_c-checkbox;
		border-radius: $brd_rd-checkbox;

		background-color: $bg-checkbox;

		cursor: pointer; }

	&__input:checked ~ &__trigger {
		border: $brd_w-checkbox_checked solid $brd_c-checkbox_checked;
		background-color: $bg-checkbox_checked; }

	&[disabled] {
		opacity: .55;
		cursor: default; } }

.-ol-radio {
	position: relative;

	width: $w-radio;
	height: $h-radio;

	&__input {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;

		opacity: 0;
		overflow: hidden;

		width: 100%;
		height: 100%;

		&[disabled] {
			cursor: default; } }

	&__trigger {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		border: $brd_w-radio solid $brd_c-radio;
		border-radius: $brd_rd-radio;

		background-color: $bg-radio;

		cursor: pointer; }

	&__input:checked ~ &__trigger {
		border: $brd_w-radio_checked solid $brd_c-radio_checked;
		background-color: $bg-radio_checked; }

	&__input[disabled] ~ &__trigger {
		border-color: $grey-lighter;
		background-color: $grey-lighter; } }

.-ol-label { // tag label
	display: flex; // to collapse margins inside
	flex-direction: column;
	margin-bottom: $m_b-label;

	&_last {
		margin-bottom: 0; } }

.-ol-label-text { // tag span
	display: block;

	color: $c-label;
	font-size: $f_sz-label;
	font-weight: $f_w-label; }

.-ol-input-wrap {
	position: relative;

	&_disabled {
		opacity: .6;

		.-ol-input,
		.-ol-textarea {
			color: $c-placeholder; }

		label {
			cursor: auto; } }

	&_error {

		.-ol-input,
		.-ol-textarea {
			border-color: $brd_c-form-error;
			color: $c-form-error; } } }

.-ol-row-input {
	margin-bottom: $m-row-input;

	&_last {
		margin-bottom: 0; } }

.-ol-row-input-mb {

	@include md-mobile {
		margin-bottom: $m-row-input-mb; } }

.-ol-form-body {
	margin-bottom: $m-form-body;

	&_last {
		margin-bottom: 0; } }
