@mixin placeholder {

	&::-webkit-input-placeholder {
		@content; }

	&::-moz-placeholder {
		@content; }

	&:-moz-placeholder {
		@content; }

	&:-ms-input-placeholder {
		@content; } }



@mixin placeholder_hover {

	&:hover::-webkit-input-placeholder {
		@content; }

	&:hover::-moz-placeholder {
		@content; }

	&:hover:-moz-placeholder {
		@content; }

	&:hover:-ms-input-placeholder {
		@content; } }



@mixin placeholder_focus {

	&:focus::-webkit-input-placeholder {
		@content; }

	&:focus::-moz-placeholder {
		@content; }

	&:focus:-moz-placeholder {
		@content; }

	&:focus:-ms-input-placeholder {
		@content; } }



@mixin placeholder-style( $color: $c-placeholder, $color_focus: transparent, $color_hover: $c-placeholder, $fw: inherit, $l_s: $l_s-input ) {

	&::-webkit-input-placeholder {
		opacity: 1; // to set browser style

		color: $color;
		font-weight: $fw;
		letter-spacing: $l_s; }

	&::-moz-placeholder {
		opacity: 1; // to set browser style

		color: $color;
		font-weight: $fw;
		letter-spacing: $l_s; }

	&:-moz-placeholder {
		opacity: 1; // to set browser style

		color: $color;
		font-weight: $fw;
		letter-spacing: $l_s; }

	&:-ms-input-placeholder {
		opacity: 1; // to set browser style

		color: $color;
		font-weight: $fw;
		letter-spacing: $l_s; }





	&:hover::-webkit-input-placeholder {
		opacity: 1; // to set browser style

		color: $color_hover;
		font-weight: $fw;
		letter-spacing: $l_s; }

	&:hover::-moz-placeholder {
		opacity: 1; // to set browser style

		color: $color_hover;
		font-weight: $fw;
		letter-spacing: $l_s; }

	&:hover:-moz-placeholder {
		opacity: 1; // to set browser style

		color: $color_hover;
		font-weight: $fw;
		letter-spacing: $l_s; }

	&:hover:-ms-input-placeholder {
		opacity: 1; // to set browser style

		color: $color_hover;
		font-weight: $fw;
		letter-spacing: $l_s; }





	&:focus::-webkit-input-placeholder {
		opacity: 1; // to set browser style

		color: $color_focus;
		font-weight: $fw;
		letter-spacing: $l_s; }

	&:focus::-moz-placeholder {
		opacity: 1; // to set browser style

		color: $color_focus;
		font-weight: $fw;
		letter-spacing: $l_s; }

	&:focus:-moz-placeholder {
		opacity: 1; // to set browser style

		color: $color_focus;
		font-weight: $fw;
		letter-spacing: $l_s; }

	&:focus:-ms-input-placeholder {
		opacity: 1; // to set browser style

		color: $color_focus;
		font-weight: $fw;
		letter-spacing: $l_s; } }
