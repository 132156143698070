.b-header-mb {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;

    display: none;

    height: $h-b-header-mb;
    width: 100%;

    padding: 20px;

    background: $white;

    @include md-mobile {
        display: block; }

    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center; }

    &__item {}

    &__logo { // tag a
        display: flex; // for &::after
        justify-content: center;

        @extend .i-planhat-horizontal;

        &::after {
            font-size: 24px; } }

    &__nav { // button tag
        position: relative;

        width: 18px;
        height: 18px;

        cursor: pointer;

        &::before,
        &::after {
            content: '';

            position: absolute;

            height: 2px;

            border-radius: 1px;

            background: $grey-darker; }

        &::before {
            top: 8px;
            right: 0;
            width: 12px; }

        &::after {
            top: 16px;
            left: 0;
            width: 15px; } }

    &__nav-icon {
        display: block;

        width: 100%;
        height: 2px;

        border-radius: 1px;

        background: $grey-darker;

        &::before {
            content: '';

            position: absolute;
            top: -10px;
            left: -10px;

            width: calc( 100% + 20px );
            height: calc( 100% + 20px ); } } }
