.b-code {
	$p_vert: 13px;
	$p_horiz: 30px;
	$p_horiz_mb: 20px;

	border-radius: 15px;
	background-color: $grey-darker;

	@include md-mobile {
		border-radius: 0; }

	&__header {
		display: flex;
		justify-content: space-between;

		padding: $p_vert $p_horiz;

		background: $grey-dark;

		@include md-mobile {
			padding: $p_vert $p_horiz_mb; }

		&:first-child {
			border-radius: 15px 15px 0 0;

			@include md-mobile {
				border-radius: 0; } } }

	&__header-item {}

	&__header-tag {
		$triangle: 7px;
		font-family: $font-family-header;

		position: relative;

		height: calc( 100% + #{ $p_vert * 2 } );
		margin: ( -$p_vert ) ( -( $p_horiz + $triangle ) ) ( -$p_vert ) 0;
		padding: 2px 20px 0;

		background-color: $brand-success;

		color: $white;
		font-size: 14px;
		line-height: 34px;
		font-weight: 700;
		letter-spacing: .02em;

		text-align: center;

		@include md-mobile {
			margin-right: -$p_horiz_mb; }

		&::before {
			content: '';

			position: absolute;
			bottom: 100%;
			right: 0;

			border: solid transparent;
			border-width: $triangle 0 0 $triangle;
			border-left-color: #5EB209;

			@include md-mobile {
				display: none; } }

		&::after {
			content: '';

			position: absolute;
			top: 0;
			right: 100%;

			border: 18px solid transparent;
			border-right-width: 12px;
			border-right-color: $brand-success;
			border-left-width: 0; } }

	&__header-text {
		margin: -6px 0;

		color: rgba( $white, .35 );
		font-size: 14px;
		line-height: 22px;
		font-weight: 600;
		letter-spacing: .02em;
		font-family: $font-family-header;

		text-transform: uppercase; }

	&__tabs {
		border-bottom: $brd_w solid rgba( $white, .1 );
		padding: 0 $p_horiz; }

	&__tabs-inner {
		display: flex;
		margin: 0 -12px; }

	&__tabs-item {
		padding: 0 12px; }

	&__tabs-tab {
		position: relative;
		padding: 13px 0;

		&::before {
			content: '';

			position: absolute;
			top: 100%;
			left: 0;

			width: 100%;
			height: $brd_w;

			background-color: rgba( $brand-danger, 0 );

			transition: .2s all; }

		&_active::before {
			background-color: $brand-danger; } }

	&__tabs-tab-text { // tag a
		display: block;

		margin: -6px 0;

		color: rgba( $white, .5 );
		font-size: 14px;
		line-height: 22px;
		letter-spacing: .02em;

		transition: .2s color; }

	&__tabs-tab_active &__tabs-tab-text,
	&__tabs-tab:hover &__tabs-tab-text {
		color: $white;

		&_hightlight {
			color: $brand-success; } }

	&__body {
		padding: 25px $p_horiz;

		@include md-mobile {
			padding: 20px $p_horiz_mb; } }

	&__code {} }
