.b-icon {

	&_in_content_table {
		position: relative;
		top: 1px; }

	&_tick {
		display: flex;

		@extend .i-tick;

		&::after {
			font-size: 12px; } } }
