.l-nav {
	display: flex;
	height: 100vh;
	overflow: hidden; // to prevent mobile horizontal scroll

	@include md-mobile {
		position: relative;
		padding-top: $h-b-header-mb; }

	&__item {

		&_nav {
			position: relative;
			z-index: $z_i-b-sidebar; // to be over outside of .l-nav__item_major

			flex-shrink: 0;

			width: $w-b-sidebar;

			@include md-mobile {
				position: absolute;
				top: 0;
				left: -$w-b-sidebar;

				height: 100%;

				transition: left .2s; } }

		&_content {
			position: relative;
			z-index: 1;

			flex-grow: 1;

			overflow: auto;

			@include md-mobile {
				left: 0;

				flex-grow: 0;
				flex-shrink: 0;

				width: 100%;

				transition: left .2s; } } }

	._nav_active &__item_nav {

		@include md-mobile {
			left: 0; } }

	._nav_active &__item_content {

		@include md-mobile {
			left: $w-b-sidebar; } } }
