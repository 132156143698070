.i-link,
.i-info,
.i-warning,
.i-tick,
.i-planhat-horizontal,
.i-folder-open,
.i-folder {

	@include font-sprite; }

.i-link {

	&::after {
		content: '\e906'; } }

.i-info {

	&::after {
		content: '\e904'; } }

.i-warning {

	&::after {
		content: '\e905'; } }

.i-tick {

	&::after {
		content: '\e903'; } }

.i-planhat-horizontal {

	&::after {
		content: '\e900'; } }

.i-folder {

	&::after {
		content: '\e902'; } }

.i-folder-open {

	&::after {
		content: '\e901'; } }
