.-ol-btn {
	display: inline-block;
	vertical-align: middle;

	margin-bottom: 0; // For input.-ol-btn
	border-style: solid;

	background-image: none; // Reset unusual Firefox-on-Android default style see https://github.com/necolas/normalize.css/issues/214

	font-weight: $f_w-btn;
	letter-spacing: $l_s-btn;

	text-align: center;

	cursor: pointer;
	touch-action: manipulation;

	transition: all .2s;

	@include btn-size( $h-btn, $brd_rd-btn, $p_vert-btn, $p_horiz-btn, $f_sz-btn, $lh-btn, $brd_w-btn );

	@include btn-variant( $c-btn-filled, $bg_c-btn-primary );

	&_default {

		@include btn-variant( $c-btn-default, $bg_c-btn-default ); }

	&_success {

		@include btn-variant( $c-btn-filled, $bg_c-btn-success ); }

	&_info {

		@include btn-variant( $c-btn-filled, $bg_c-btn-info ); }

	&_warning {

		@include btn-variant( $c-btn-filled, $bg_c-btn-warning ); }

	&_danger {

		@include btn-variant( $c-btn-filled, $bg_c-btn-danger ); }

	&_outline {

		@include btn-variant( $c-btn-outline, $bg_c-btn-outline, $brd_c-btn: $c-btn-outline ); }

	&[disabled] {

		@include btn-variant( $c-btn-disabled, $bg_c-btn-disabled, 0, 0 );

		cursor: not-allowed;
		pointer-events: none; } // Future-proof disabling of hover, active, click

	&_link {
		width: auto;
		height: auto;

		border: 0;
		border-radius: 0;
		padding: 0;

		@include btn-link( $brd-c-btn-link, $c-btn-link, $brd-c-btn-link_hover, $c-btn-link_hover ); }


	// Button Sizes
	// --------------------------------------------------

	&_lg {

		@include btn-size( $h-btn_lg, $brd_rd-btn_lg, $p_vert-btn_lg, $p_horiz-btn_lg, $f_sz-btn_lg, $lh-btn_lg, $brd_w-btn_lg ); }

	&_sm {

		@include btn-size( $h-btn_sm, $brd_rd-btn_sm, $p_vert-btn_sm, $p_horiz-btn_sm, $f_sz-btn_sm, $lh-btn_sm, $brd_w-btn_sm ); }

	&_xs {

		@include btn-size( $h-btn_xs, $brd_rd-btn_xs, $p_vert-btn_xs, $p_horiz-btn_xs, $f_sz-btn_xs, $lh-btn_xs, $brd_w-btn_xs ); }

	&_min_w_initial {
		min-width: 0 !important; } }
