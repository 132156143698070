// http://meyerweb.com/eric/tools/css/reset/
//  v2.0b2 | 201101
//  NOTE: THIS IS A BETA VERSION (see previous line)
//  USE WITH CAUTION AND TEST WITH ABANDON

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
hr,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
button,
fieldset, form, label, legend, textarea, input, select,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	vertical-align: baseline;

	margin: 0;
	padding: 0;
	border: 0;

	font-size: 13px;
	// font: inherit

	font-smoothing: antialiased; }

// HTML5 display-role reset for older browsers
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block; }

iframe, svg {
	display: block; }

body {
	line-height: 1; }

ol, ul {
	list-style: none; }

blockquote, q {
	quotes: none; }

blockquote::before, blockquote::after,
q::before, q::after {
	content: '';
	content: none; }

img,
video {
	display: block;
	max-width: 100%; }

table {
	border-collapse: collapse;
	border-spacing: 0; }

th {
	text-align: left; }

// remember to define focus styles!
*:focus {
	outline: 0; }

// remember to highlight inserts somehow!
ins {
	text-decoration: none; }

// remove indent in Firefox
button,
input,
textarea,
select,
a {
	background-color: transparent;

	font-family: inherit;
	font-size: inherit;
	line-height: inherit; }

button,
a {
	letter-spacing: inherit;
	word-spacing: inherit;
	cursor: pointer; }

button {
	display: block;
	overflow: visible; // for reset ie style
	color: inherit; } // default color in Chrome #000

button,
input[type='submit'],
input[type='reset'],
input[type='file'] {
	text-align: left; }

input {
	word-break: normal; // set browser common styles

	&[type="checkbox"],
	&[type="radio"] {
		cursor: pointer; }

	&[type="color"] {
		border: none;
		outline: none;
		-webkit-appearance: none;

		&::-webkit-color-swatch-wrapper {
			padding: 0; }

		&::-moz-color-swatch-wrapper {
			padding: 0; }

		&::-webkit-color-swatch {
			border: none; }

		&::-moz-color-swatch {
			border: none; } } }

input,
textarea,
select {
	display: block;
	-moz-appearance: none; // to reset initial browser style
	-webkit-appearance: none; } // to reset initial browser style

label {
	cursor: pointer; }

a {
	color: inherit;
	text-decoration: inherit; }

mark {
	background-color: transparent;
	color: inherit; }

pre {
	white-space: pre-wrap; }

// remove indent in Firefox
@-moz-document url-prefix() {

	button::-moz-focus-inner,
	input[type="reset"]::-moz-focus-inner,
	input[type="button"]::-moz-focus-inner,
	input[type="submit"]::-moz-focus-inner,
	input[type="file"] > input[type="button"]::-moz-focus-inner {
		margin: 0;
		border: 0;
		padding: 0; } }
