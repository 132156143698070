.b-sidebar {
	$p: 20px;

	padding: 20px;

	background: $grey-ph;

	color: $white;

	overflow-y: auto;

	overflow-x: hidden;

	&__logo {
		margin-bottom: 15px;
		border-bottom: $brd_w solid rgba( $white, .1 );
		padding-bottom: 20px; }

	&__logo-img {
		display: flex;
		justify-content: start;
		img {
			width: 45px; } }

	&__nav {
		margin: 0 ( -$p ); }

	&__nav-item {}

	&__nav-link { // tag a
		position: relative; // for &::before

		display: block;

		padding: 15px $p;

		&::before {
			content: '';

			position: absolute;
			top: 0;
			right: calc( 100% + #{ $w-b-sidebar__nav-link-arrow } );

			width: 100%;
			height: 100%;

			background-color: $black-ph;

			transition: right .2s; }

		&._active::before {
			right: 0;

			@include md-mobile {
				right: calc( 100% + #{ $w-b-sidebar__nav-link-arrow } ); } }

		._nav_active &._active::before {

			@include md-mobile {
				right: 0; } }

		// &::after
		// 	content: ''

		// 	position: absolute
		// 	top: 0
		// 	right: 100%

		// 	border: 20px solid transparent
		// 	border-left-width: $w-b-sidebar__nav-link-arrow
		// 	border-right-width: 0
		// 	border-left-color: $grey-darker

		// 	transition: right .2s

		// &._active::after
		// 	right: -$w-b-sidebar__nav-link-arrow

		// 	+md-mobile
		// 		right: 100%

		// ._nav_active &._active::after

		// 	+md-mobile
 }		// 		right: -$w-b-sidebar__nav-link-arrow

	&__nav-link-text {
		position: relative;
		z-index: 1;
		font-family: $font-family-header;

		margin: -6px 0;

		color: $white-light;
		font-size: 14px;
		line-height: 22px;
		font-weight: 600;
		letter-spacing: .04em;

		transition: .2s color; }

	&__nav-link._active &__nav-link-text,
	&__nav-link:hover &__nav-link-text {
		color: $white; }

	&__subnav {
		display: none;
		padding: 4px 0; }

	&__nav-link._active ~ &__subnav {
		display: block; }

	&__subnav-item {}

	&__subnav-link { // tag a
		position: relative; // for &::after
		display: block;
		padding: 11px $p 11px 65px;

		@extend .i-folder;

		&._active,
		&._open {

			@extend .i-folder-open; }

		&::before {
			content: '';

			position: absolute;
			top: 14px;
			left: 48px;

			border: 3px solid transparent;
			border-bottom-width: 0;
			border-top-color: rgba( $white, .4 );

			transform: rotateZ( -90deg );

			transition: .2s all; }

		&:not( &._active ):hover::before {
			border-top-color: $white; }

		&._active::before,
		&._open::before {
			transform: rotateZ( 0deg ); }

		&._open::before {
			border-top-color: rgba( $white, .4 ); }

		&._active::before {
			border-top-color: $brand-warning-2; }

		&::after {
			position: absolute;
			top: 8px;
			left: 21px;

			color: rgba( $white, .4 );
			font-size: 14px;

			transition: .2s color; }

		&:not( &._active ):hover::after {
			color: $white; }

		&._active::after {
			left: 19px;
			color: $brand-warning-2; } }

	&__subnav-link-text {
		margin: -6px 0 -4px;

		color: rgba( $white, .6 );
		font-size: 13px;
		line-height: 19px;
		font-weight: 600;
		letter-spacing: .04em;

		transition: .2s color; }

	&__subnav-link._active &__subnav-link-text,
	&__subnav-link:hover &__subnav-link-text {
		color: $white; }

	&__subnav-inner {
		display: none; }

	&__subnav-link._active ~ &__subnav-inner {
		display: block; }

	&__subnav-inner-item {}

	&__subnav-inner-link { // tag a
		position: relative; // for &__subnav-inner-link-subtext
		display: block;
		padding: 11px $p 11px 65px; }

	&__subnav-inner-link-subtext {
		position: absolute;
		top: 13px;
		left: 18px;

		margin: -4px 0;

		color: rgba( $white, .25 );
		font-size: 10px;
		line-height: 15px;
		font-weight: 600;
		letter-spacing: .02em;

		text-transform: uppercase;

		transition: .2s color; }

	&__subnav-inner-link:hover &__subnav-inner-link-subtext {
		color: $white; }

	&__subnav-inner-link._active &__subnav-inner-link-subtext {
		color: $brand-warning-2; }

	&__subnav-inner-link-text {
		margin: -5px 0 -4px;

		color: rgba( $white, .6 );
		font-size: 12px;
		line-height: 18px;
		letter-spacing: .02em;

		transition: .2s color; }

	&__subnav-inner-link._active &__subnav-inner-link-text,
	&__subnav-inner-link:hover &__subnav-inner-link-text {
		color: $white; } }
