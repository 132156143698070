.l-code {
	$p: 60px;
	$p_dlg: 55px;
	$p_mb: 20px;

	&:not( :last-child ) {
		border-bottom: $brd_w solid $brd_c; }

	&__inner {
		max-width: 2000px;
		margin: 0 auto;
		padding: $p;

		@include md-desk_xl {
			max-width: 2500px; }

		@include md-desk_lg {
			padding: $p_dlg; }

		@include md-mobile {
			padding: $p_mb; } }

	&__content {
		display: flex;
		margin: -$p/2;

		@include md-desk_lg {
			margin: -$p_dlg/2; }

		@include md-desk_sm {
			flex-direction: column; }

		@include md-mobile {
			margin: -$p_mb/2; } }

	&__item {
		width: 50%;
		padding: $p/2;

		@include md-desk_lg {
			padding: $p_dlg/2; }

		@include md-desk_sm {
			width: 100%;
			max-width: 640px;
			margin: 0 auto; }

		@include md-mobile {
			padding: $p_mb/2; }

		&_wide {
			width: 100%;
			max-width: 920px; } }

	&__code {
		position: sticky;
		top: 0;

		margin-top: -19px;
		padding-top: $p;

		@include md-desk_lg {
			margin-top: 1px;
			padding-top: $p_dlg; }

		@include md-mobile {
			margin: 0 ( -$p_mb ) ( -$p_mb );
			padding-top: 10px; } } }
