/*----------- main -----------*/
/*----------- main (end) -----------*/
/*----------- typography -----------*/
/*----------- typography (end) -----------*/
/*----------- common -----------*/
/*----------- common (end) -----------*/
/*----------- link -----------*/
/*----------- link (end) -----------*/
/*----------- form -----------*/
/*----------- form (end) -----------*/
/*----------- table -----------*/
/*----------- table (end) -----------*/
/*----------- button -----------*/
/*----------- button (end) -----------*/
/*----------- images -----------*/
/*----------- images (end) -----------*/
/*----------- fonts -----------*/
/*----------- fonts (end) -----------*/
/*----------- usage -----------*/
/*----------- usage (end) -----------*/
/*----------- typography -----------*/
/*----------- typography (end) -----------*/
/*----------- common -----------*/
/*----------- common (end) -----------*/
/*----------- media -----------*/
/*----------- media (end) -----------*/
/*----------- link -----------*/
/*----------- link (end) -----------*/
/*----------- form -----------*/
/*----------- form (end) -----------*/
/*----------- table -----------*/
/*----------- table (end) -----------*/
/*----------- button -----------*/
/*----------- button (end) -----------*/
/* settings */
/*----------- main -----------*/
/*----------- main (end) -----------*/
/*----------- typography -----------*/
/*----------- typography (end) -----------*/
/*----------- common -----------*/
/*----------- common (end) -----------*/
/*----------- link -----------*/
/*----------- link (end) -----------*/
/*----------- form -----------*/
/*----------- form (end) -----------*/
/*----------- table -----------*/
/*----------- table (end) -----------*/
/*----------- button -----------*/
/*----------- button (end) -----------*/
/*----------- images -----------*/
/*----------- images (end) -----------*/
/*----------- fonts -----------*/
/*----------- fonts (end) -----------*/
/*----------- usage -----------*/
/*----------- usage (end) -----------*/
/*----------- typography -----------*/
/*----------- typography (end) -----------*/
/*----------- common -----------*/
/*----------- common (end) -----------*/
/*----------- media -----------*/
/*----------- media (end) -----------*/
/*----------- link -----------*/
/*----------- link (end) -----------*/
/*----------- form -----------*/
/*----------- form (end) -----------*/
/*----------- table -----------*/
/*----------- table (end) -----------*/
/*----------- button -----------*/
/*----------- button (end) -----------*/
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/sprite/icomoon.eot?43t99a");
  src: url("../fonts/sprite/icomoon.eot?43t99a#iefix") format("embedded-opentype"), url("../fonts/sprite/icomoon.ttf?43t99a") format("truetype"), url("../fonts/sprite/icomoon.woff?43t99a") format("woff"), url("../fonts/sprite/icomoon.svg?43t99a#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Aeonik';
  src: url("../fonts/Aeonik-Medium-Web/Aeonik-Medium.woff2") format("woff2"), url("../fonts/Aeonik-Medium-Web/Aeonik-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

*:root {
  /*----------- colors -----------*/
  /*----------- main -----------*/
  --black:					$black;
  --grey-darker:				$grey-darker;
  --grey-dark:				$grey-dark;
  --grey:						$grey;
  --grey-light:				$grey-light;
  --grey-lighter:				$grey-lighter;
  --white:					$white;
  --brand-primary:			$brand-primary;
  --brand-success:			$brand-success;
  --brand-info:				$brand-info;
  --brand-warning:			$brand-warning;
  --brand-danger:				$brand-danger;
  /*----------- main (end) -----------*/
  /*----------- common -----------*/
  --bg_c-body:				$bg_c-body;
  --brd_c:					$brd_c;
  /*----------- common (end) -----------*/
  /*----------- colors (end) -----------*/
  /*----------- options -----------*/
  /*----------- media -----------*/
  --md-desk_md:				$md-desk_md;
  --md-desk_sm:				$md-desk_sm;
  --md-tablet:				$md-tablet;
  --md-mobile:				$md-mobile;
  --md-mobile_sm:				$md-mobile_sm;
  /*----------- media (end) -----------*/
  /*----------- options (end) -----------*/ }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
hr,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
button,
fieldset, form, label, legend, textarea, input, select,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 13px;
  font-smoothing: antialiased; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

iframe, svg {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote::before, blockquote::after,
q::before, q::after {
  content: '';
  content: none; }

img,
video {
  display: block;
  max-width: 100%; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

th {
  text-align: left; }

*:focus {
  outline: 0; }

ins {
  text-decoration: none; }

button,
input,
textarea,
select,
a {
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
a {
  letter-spacing: inherit;
  word-spacing: inherit;
  cursor: pointer; }

button {
  display: block;
  overflow: visible;
  color: inherit; }

button,
input[type='submit'],
input[type='reset'],
input[type='file'] {
  text-align: left; }

input {
  word-break: normal; }
  input[type="checkbox"], input[type="radio"] {
    cursor: pointer; }
  input[type="color"] {
    border: none;
    outline: none;
    -webkit-appearance: none; }
    input[type="color"]::-webkit-color-swatch-wrapper {
      padding: 0; }
    input[type="color"]::-moz-color-swatch-wrapper {
      padding: 0; }
    input[type="color"]::-webkit-color-swatch {
      border: none; }
    input[type="color"]::-moz-color-swatch {
      border: none; }

input,
textarea,
select {
  display: block;
  -moz-appearance: none;
  -webkit-appearance: none; }

label {
  cursor: pointer; }

a {
  color: inherit;
  text-decoration: inherit; }

mark {
  background-color: transparent;
  color: inherit; }

pre {
  white-space: pre-wrap; }

@-moz-document url-prefix() {
  button::-moz-focus-inner,
  input[type="reset"]::-moz-focus-inner,
  input[type="button"]::-moz-focus-inner,
  input[type="submit"]::-moz-focus-inner,
  input[type="file"] > input[type="button"]::-moz-focus-inner {
    margin: 0;
    border: 0;
    padding: 0; } }

.-ol-btn {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  border-style: solid;
  background-image: none;
  font-weight: 700;
  letter-spacing: 0.06em;
  text-align: center;
  cursor: pointer;
  touch-action: manipulation;
  transition: all .2s;
  height: auto;
  min-height: auto;
  border-radius: 4px;
  border-width: 1px;
  padding: 7px 25px;
  font-size: 1.6rem;
  line-height: 24px;
  border-color: #1251F2;
  background: #1251F2;
  color: #fff;
  transition: all .2s; }
  .-ol-btn:hover {
    border-color: #0c47de;
    background: #0c47de;
    color: #fff; }
  .-ol-btn:active {
    border-color: #0b40c6;
    background: #0b40c6;
    color: #fff; }
  .-ol-btn_default {
    border-color: #fff;
    background: #fff;
    color: #1251F2;
    transition: all .2s; }
    .-ol-btn_default:hover {
      border-color: #f2f2f2;
      background: #f2f2f2;
      color: #1251F2; }
    .-ol-btn_default:active {
      border-color: #e6e6e6;
      background: #e6e6e6;
      color: #1251F2; }
  .-ol-btn_success {
    border-color: #72DA0B;
    background: #72DA0B;
    color: #fff;
    transition: all .2s; }
    .-ol-btn_success:hover {
      border-color: #65c20a;
      background: #65c20a;
      color: #fff; }
    .-ol-btn_success:active {
      border-color: #59a909;
      background: #59a909;
      color: #fff; }
  .-ol-btn_info {
    border-color: #30D0F3;
    background: #30D0F3;
    color: #fff;
    transition: all .2s; }
    .-ol-btn_info:hover {
      border-color: #18cbf2;
      background: #18cbf2;
      color: #fff; }
    .-ol-btn_info:active {
      border-color: #0dbce3;
      background: #0dbce3;
      color: #fff; }
  .-ol-btn_warning {
    border-color: #FFAF13;
    background: #FFAF13;
    color: #fff;
    transition: all .2s; }
    .-ol-btn_warning:hover {
      border-color: #f9a400;
      background: #f9a400;
      color: #fff; }
    .-ol-btn_warning:active {
      border-color: #df9300;
      background: #df9300;
      color: #fff; }
  .-ol-btn_danger {
    border-color: #F8144A;
    background: #F8144A;
    color: #fff;
    transition: all .2s; }
    .-ol-btn_danger:hover {
      border-color: #eb073d;
      background: #eb073d;
      color: #fff; }
    .-ol-btn_danger:active {
      border-color: #d30637;
      background: #d30637;
      color: #fff; }
  .-ol-btn_outline {
    border-color: #1251F2;
    background: transparent;
    color: #1251F2;
    transition: all .2s; }
    .-ol-btn_outline:hover {
      border-color: #0c47de;
      background: rgba(0, 0, 0, 0);
      color: #1251F2; }
    .-ol-btn_outline:active {
      border-color: #0b40c6;
      background: rgba(0, 0, 0, 0);
      color: #1251F2; }
  .-ol-btn[disabled] {
    border-color: #cccccc;
    background: #cccccc;
    color: #fff;
    transition: all .2s;
    cursor: not-allowed;
    pointer-events: none; }
    .-ol-btn[disabled]:hover {
      border-color: #cccccc;
      background: #cccccc;
      color: #fff; }
    .-ol-btn[disabled]:active {
      border-color: #cccccc;
      background: #cccccc;
      color: #fff; }
  .-ol-btn_link {
    width: auto;
    height: auto;
    border: 0;
    border-radius: 0;
    padding: 0;
    border-color: transparent;
    border-bottom: 1px solid transparent;
    color: #1251F2; }
    .-ol-btn_link:hover {
      border-bottom-color: fadeout(#1251F2, 20%);
      color: #1251F2; }
  .-ol-btn_lg {
    height: auto;
    min-height: auto;
    border-radius: 6px;
    border-width: 2px;
    padding: 8px 30px;
    font-size: 2rem;
    line-height: 30px; }
  .-ol-btn_sm {
    height: auto;
    min-height: auto;
    border-radius: 3px;
    border-width: 1px;
    padding: 6.5px 20px;
    font-size: 1.4rem;
    line-height: 21px; }
  .-ol-btn_xs {
    height: auto;
    min-height: auto;
    border-radius: 3px;
    border-width: 1px;
    padding: 4.5px 15px;
    font-size: 1.4rem;
    line-height: 21px; }
  .-ol-btn_min_w_initial {
    min-width: 0 !important; }

*::selection {
  background: #1251F2;
  color: #fff; }

* {
  box-sizing: border-box; }

*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: "Inter", sans-serif !important;
  font-variant-ligatures: no-common-ligatures !important;
  font-size: 10px;
  text-size-adjust: 100%;
  line-height: 1.2;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  word-wrap: break-word;
  word-break: normal;
  word-break: break-word; }

body {
  position: relative;
  min-width: 320px;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: visible;
  background-color: #fff;
  color: #000;
  font-family: "Inter", sans-serif !important;
  font-size: 1.6rem;
  line-height: 1.35;
  font-weight: 400;
  -webkit-overflow-scrolling: touch; }
  @media only screen and (max-width: 640px) {
    body {
      min-height: auto; } }
  body._no_scroll {
    height: 100%;
    overflow: hidden; }
  body._mobile_test {
    width: 320px; }

.-ol-input,
.-ol-textarea {
  display: block;
  width: 100%;
  border: 1px solid #E2E7ED;
  border-radius: 2px;
  padding: 6px 11px;
  background: #fff;
  color: #000;
  font-size: 1.6rem;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-overflow: ellipsis; }
  .-ol-input::-webkit-input-placeholder,
  .-ol-textarea::-webkit-input-placeholder {
    opacity: 1;
    color: #B7BFCE;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input::-moz-placeholder,
  .-ol-textarea::-moz-placeholder {
    opacity: 1;
    color: #B7BFCE;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:-moz-placeholder,
  .-ol-textarea:-moz-placeholder {
    opacity: 1;
    color: #B7BFCE;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:-ms-input-placeholder,
  .-ol-textarea:-ms-input-placeholder {
    opacity: 1;
    color: #B7BFCE;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:hover::-webkit-input-placeholder,
  .-ol-textarea:hover::-webkit-input-placeholder {
    opacity: 1;
    color: #B7BFCE;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:hover::-moz-placeholder,
  .-ol-textarea:hover::-moz-placeholder {
    opacity: 1;
    color: #B7BFCE;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:hover:-moz-placeholder,
  .-ol-textarea:hover:-moz-placeholder {
    opacity: 1;
    color: #B7BFCE;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:hover:-ms-input-placeholder,
  .-ol-textarea:hover:-ms-input-placeholder {
    opacity: 1;
    color: #B7BFCE;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:focus::-webkit-input-placeholder,
  .-ol-textarea:focus::-webkit-input-placeholder {
    opacity: 1;
    color: transparent;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:focus::-moz-placeholder,
  .-ol-textarea:focus::-moz-placeholder {
    opacity: 1;
    color: transparent;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:focus:-moz-placeholder,
  .-ol-textarea:focus:-moz-placeholder {
    opacity: 1;
    color: transparent;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:focus:-ms-input-placeholder,
  .-ol-textarea:focus:-ms-input-placeholder {
    opacity: 1;
    color: transparent;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input[readonly], .-ol-input[disabled],
  .-ol-textarea[readonly],
  .-ol-textarea[disabled] {
    color: #B7BFCE;
    cursor: default; }

.-ol-input {
  height: 34px; }

.-ol-textarea {
  height: 74px;
  resize: vertical;
  overflow: auto; }

.-ol-input-file {
  position: relative;
  overflow: hidden;
  width: 100%; }
  .-ol-input-file input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    opacity: 0;
    cursor: pointer; }

.-ol-label {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; }
  .-ol-label_last {
    margin-bottom: 0; }

.-ol-label-text {
  display: block;
  color: #000;
  font-size: 1.6rem;
  font-weight: 700; }

.-ol-input-wrap {
  position: relative; }
  .-ol-input-wrap_disabled {
    opacity: .6; }
    .-ol-input-wrap_disabled .-ol-input,
    .-ol-input-wrap_disabled .-ol-textarea {
      color: #B7BFCE; }
    .-ol-input-wrap_disabled label {
      cursor: auto; }
  .-ol-input-wrap_error .-ol-input,
  .-ol-input-wrap_error .-ol-textarea {
    border-color: #F8144A;
    color: #F8144A; }

.-ol-row-input {
  margin-bottom: 10px; }
  .-ol-row-input_last {
    margin-bottom: 0; }

.-ol-form-body {
  margin-bottom: 20px; }
  .-ol-form-body_last {
    margin-bottom: 0; }

.-ol-table {
  width: 100%;
  text-align: left; }
  .-ol-table td,
  .-ol-table th {
    padding: 5px 5px;
    vertical-align: middle; }
  .-ol-table thead th {
    font-size: 1.1em;
    font-weight: 600; }
  .-ol-table_even tbody tr:nth-child(2n) {
    background: #f2f2f2; }
  .-ol-table_odd tbody tr:nth-child(2n-1) {
    background: #f2f2f2; }

.-ol-thead {
  border-color: #ebebeb;
  border-style: solid;
  border-bottom-width: 1px; }

table .b-auto-tag {
  font-size: 10px;
  font-weight: bold; }

.-ol-content h1,
.-ol-h1,
.-ol-content h2,
.-ol-h2,
.-ol-content h3,
.-ol-h3,
.-ol-content h4,
.-ol-h4,
.-ol-content h5,
.-ol-h5,
.-ol-content h6,
.-ol-h6 {
  color: #000;
  line-height: 1.35;
  font-weight: 400; }

.-ol-content h1,
.-ol-h1 {
  margin-bottom: 20px;
  font-size: 3.6rem; }

.-ol-content h2,
.-ol-h2 {
  margin-bottom: 15px;
  font-size: 3rem; }

.-ol-content h3,
.-ol-h3 {
  margin-bottom: 10px;
  font-size: 2.4rem; }

.-ol-content h4,
.-ol-h4 {
  margin-bottom: 7px;
  font-size: 1.8rem; }

.-ol-content h5,
.-ol-h5 {
  margin-bottom: 5px;
  font-size: 1.6rem; }

.-ol-content h6,
.-ol-h6 {
  margin-bottom: 5px;
  font-size: 1.2rem; }

.-ol-h-none {
  margin-bottom: 0;
  color: #000;
  font-family: "Inter", sans-serif !important;
  font-size: 1.6rem;
  line-height: 1.35;
  letter-spacing: 0.06em;
  font-weight: 400;
  word-spacing: 0;
  text-transform: none; }

.-ol-content a,
.-ol-link {
  display: inline;
  border-bottom: 1px solid transparent;
  color: #1251F2;
  line-height: inherit;
  letter-spacing: inherit;
  cursor: pointer;
  transition: all .2s; }
  .-ol-content a:hover,
  .-ol-link:hover {
    border-bottom-color: #0b40c6;
    color: #0b40c6; }

.-ol-content b,
.-ol-b,
.-ol-content strong,
.-ol-strong {
  font-weight: bold;
  letter-spacing: inherit; }

.-ol-content i,
.-ol-i,
.-ol-content em,
.-ol-em {
  font-style: italic;
  letter-spacing: inherit; }

.-ol-content sub,
.-ol-sub,
.-ol-content sup,
.-ol-sup {
  font-size: .6em; }

.-ol-content sub,
.-ol-sub {
  vertical-align: bottom; }

.-ol-content sup,
.-ol-sup {
  vertical-align: top; }

.-ol-container {
  position: relative;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 30px; }
  @media only screen and (max-width: 1170px) {
    .-ol-container {
      width: 100%;
      max-width: none;
      padding: 0 20px; } }
  .-ol-container_full_w {
    width: 100%;
    max-width: none;
    padding: 0 30px; }
    @media only screen and (max-width: 1170px) {
      .-ol-container_full_w {
        padding: 0 20px; } }

.-ol-clfx::before, .-ol-clfx::after {
  content: "";
  display: table; }

.-ol-clfx::after {
  clear: both; }

.-ol-row,
.-ol-row-float,
.-ol-row-inline,
.-ol-row-table {
  margin-left: -15px;
  margin-right: -15px; }
  @media only screen and (max-width: 1170px) {
    .-ol-row,
    .-ol-row-float,
    .-ol-row-inline,
    .-ol-row-table {
      margin-left: -10px;
      margin-right: -10px; } }

.-ol-row {
  position: relative;
  display: flex;
  flex-wrap: wrap; }

.-ol-row-inline {
  position: relative;
  font-size: 0 !important;
  letter-spacing: 0 !important; }
  .-ol-row-inline > .-ol-col {
    display: inline-block;
    vertical-align: top;
    font-size: 1.6rem;
    letter-spacing: 0.06em; }
  .-ol-row-inline > .-ol-col_recovery {
    font-size: 1.6rem;
    letter-spacing: 0.06em; }

.-ol-row-table {
  position: relative;
  display: table;
  height: 100%;
  width: calc( 100% + 30px);
  table-layout: fixed; }
  .-ol-row-table > .-ol-col_table {
    display: table-cell;
    vertical-align: top; }
  .-ol-row-table > .-ol-col_table_major {
    width: 100%; }

.-ol-row-float {
  position: relative; }
  .-ol-row-float::before, .-ol-row-float::after {
    content: "";
    display: table; }
  .-ol-row-float::after {
    clear: both; }
  .-ol-row-float > [class*='-ol-col'] {
    float: left; }

.-ol-row_without_inside_space {
  margin: 0; }

[class*=-ol-col-] {
  position: relative;
  vertical-align: top;
  padding: 0 15px; }
  @media only screen and (max-width: 1170px) {
    [class*=-ol-col-] {
      width: 100%;
      padding: 0 10px; } }

.-ol-col_without_inside_space {
  padding: 0; }

.-ol-col_valign_m {
  vertical-align: middle !important; }

.-ol-col_valign_b {
  vertical-align: bottom !important; }

[class*=-ol-col-offset] {
  margin-left: 0; }

@media only screen and (min-width: 1171px) {
  .-ol-col-0 {
    width: 0%; }
  .-ol-col-offset-0 {
    margin-left: 0%; } }

@media only screen and (min-width: 1171px) {
  .-ol-col-1 {
    width: 8.33333%; }
  .-ol-col-offset-1 {
    margin-left: 8.33333%; } }

@media only screen and (min-width: 1171px) {
  .-ol-col-2 {
    width: 16.66667%; }
  .-ol-col-offset-2 {
    margin-left: 16.66667%; } }

@media only screen and (min-width: 1171px) {
  .-ol-col-3 {
    width: 25%; }
  .-ol-col-offset-3 {
    margin-left: 25%; } }

@media only screen and (min-width: 1171px) {
  .-ol-col-4 {
    width: 33.33333%; }
  .-ol-col-offset-4 {
    margin-left: 33.33333%; } }

@media only screen and (min-width: 1171px) {
  .-ol-col-5 {
    width: 41.66667%; }
  .-ol-col-offset-5 {
    margin-left: 41.66667%; } }

@media only screen and (min-width: 1171px) {
  .-ol-col-6 {
    width: 50%; }
  .-ol-col-offset-6 {
    margin-left: 50%; } }

@media only screen and (min-width: 1171px) {
  .-ol-col-7 {
    width: 58.33333%; }
  .-ol-col-offset-7 {
    margin-left: 58.33333%; } }

@media only screen and (min-width: 1171px) {
  .-ol-col-8 {
    width: 66.66667%; }
  .-ol-col-offset-8 {
    margin-left: 66.66667%; } }

@media only screen and (min-width: 1171px) {
  .-ol-col-9 {
    width: 75%; }
  .-ol-col-offset-9 {
    margin-left: 75%; } }

@media only screen and (min-width: 1171px) {
  .-ol-col-10 {
    width: 83.33333%; }
  .-ol-col-offset-10 {
    margin-left: 83.33333%; } }

@media only screen and (min-width: 1171px) {
  .-ol-col-11 {
    width: 91.66667%; }
  .-ol-col-offset-11 {
    margin-left: 91.66667%; } }

@media only screen and (min-width: 1171px) {
  .-ol-col-12 {
    width: 100%; }
  .-ol-col-offset-12 {
    margin-left: 100%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-0 {
    width: 0%; }
  .-ol-col-dm-offset-0 {
    margin-left: 0%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-1 {
    width: 8.33333%; }
  .-ol-col-dm-offset-1 {
    margin-left: 8.33333%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-2 {
    width: 16.66667%; }
  .-ol-col-dm-offset-2 {
    margin-left: 16.66667%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-3 {
    width: 25%; }
  .-ol-col-dm-offset-3 {
    margin-left: 25%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-4 {
    width: 33.33333%; }
  .-ol-col-dm-offset-4 {
    margin-left: 33.33333%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-5 {
    width: 41.66667%; }
  .-ol-col-dm-offset-5 {
    margin-left: 41.66667%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-6 {
    width: 50%; }
  .-ol-col-dm-offset-6 {
    margin-left: 50%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-7 {
    width: 58.33333%; }
  .-ol-col-dm-offset-7 {
    margin-left: 58.33333%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-8 {
    width: 66.66667%; }
  .-ol-col-dm-offset-8 {
    margin-left: 66.66667%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-9 {
    width: 75%; }
  .-ol-col-dm-offset-9 {
    margin-left: 75%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-10 {
    width: 83.33333%; }
  .-ol-col-dm-offset-10 {
    margin-left: 83.33333%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-11 {
    width: 91.66667%; }
  .-ol-col-dm-offset-11 {
    margin-left: 91.66667%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-12 {
    width: 100%; }
  .-ol-col-dm-offset-12 {
    margin-left: 100%; } }

@media only screen and (max-width: 1170px) {
  .-ol-col-tb-0 {
    width: 0%; }
  .-ol-col-tb-offset-0 {
    margin-left: 0%; } }

@media only screen and (max-width: 1170px) {
  .-ol-col-tb-1 {
    width: 8.33333%; }
  .-ol-col-tb-offset-1 {
    margin-left: 8.33333%; } }

@media only screen and (max-width: 1170px) {
  .-ol-col-tb-2 {
    width: 16.66667%; }
  .-ol-col-tb-offset-2 {
    margin-left: 16.66667%; } }

@media only screen and (max-width: 1170px) {
  .-ol-col-tb-3 {
    width: 25%; }
  .-ol-col-tb-offset-3 {
    margin-left: 25%; } }

@media only screen and (max-width: 1170px) {
  .-ol-col-tb-4 {
    width: 33.33333%; }
  .-ol-col-tb-offset-4 {
    margin-left: 33.33333%; } }

@media only screen and (max-width: 1170px) {
  .-ol-col-tb-5 {
    width: 41.66667%; }
  .-ol-col-tb-offset-5 {
    margin-left: 41.66667%; } }

@media only screen and (max-width: 1170px) {
  .-ol-col-tb-6 {
    width: 50%; }
  .-ol-col-tb-offset-6 {
    margin-left: 50%; } }

@media only screen and (max-width: 1170px) {
  .-ol-col-tb-7 {
    width: 58.33333%; }
  .-ol-col-tb-offset-7 {
    margin-left: 58.33333%; } }

@media only screen and (max-width: 1170px) {
  .-ol-col-tb-8 {
    width: 66.66667%; }
  .-ol-col-tb-offset-8 {
    margin-left: 66.66667%; } }

@media only screen and (max-width: 1170px) {
  .-ol-col-tb-9 {
    width: 75%; }
  .-ol-col-tb-offset-9 {
    margin-left: 75%; } }

@media only screen and (max-width: 1170px) {
  .-ol-col-tb-10 {
    width: 83.33333%; }
  .-ol-col-tb-offset-10 {
    margin-left: 83.33333%; } }

@media only screen and (max-width: 1170px) {
  .-ol-col-tb-11 {
    width: 91.66667%; }
  .-ol-col-tb-offset-11 {
    margin-left: 91.66667%; } }

@media only screen and (max-width: 1170px) {
  .-ol-col-tb-12 {
    width: 100%; }
  .-ol-col-tb-offset-12 {
    margin-left: 100%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-0 {
    width: 0%; }
  .-ol-col-mb-offset-0 {
    margin-left: 0%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-1 {
    width: 8.33333%; }
  .-ol-col-mb-offset-1 {
    margin-left: 8.33333%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-2 {
    width: 16.66667%; }
  .-ol-col-mb-offset-2 {
    margin-left: 16.66667%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-3 {
    width: 25%; }
  .-ol-col-mb-offset-3 {
    margin-left: 25%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-4 {
    width: 33.33333%; }
  .-ol-col-mb-offset-4 {
    margin-left: 33.33333%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-5 {
    width: 41.66667%; }
  .-ol-col-mb-offset-5 {
    margin-left: 41.66667%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-6 {
    width: 50%; }
  .-ol-col-mb-offset-6 {
    margin-left: 50%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-7 {
    width: 58.33333%; }
  .-ol-col-mb-offset-7 {
    margin-left: 58.33333%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-8 {
    width: 66.66667%; }
  .-ol-col-mb-offset-8 {
    margin-left: 66.66667%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-9 {
    width: 75%; }
  .-ol-col-mb-offset-9 {
    margin-left: 75%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-10 {
    width: 83.33333%; }
  .-ol-col-mb-offset-10 {
    margin-left: 83.33333%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-11 {
    width: 91.66667%; }
  .-ol-col-mb-offset-11 {
    margin-left: 91.66667%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-12 {
    width: 100%; }
  .-ol-col-mb-offset-12 {
    margin-left: 100%; } }

.-ol-gap-remover {
  margin-left: -15px;
  margin-right: -15px; }
  @media only screen and (max-width: 1170px) {
    .-ol-gap-remover {
      margin: 0; } }

@media only screen and (max-width: 1170px) {
  .-ol-gap-remover-tb {
    margin-left: -10px;
    margin-right: -10px; } }

@media only screen and (max-width: 640px) {
  .-ol-gap-remover-mb {
    margin-left: -10px;
    margin-right: -10px; } }

._pos_relative {
  position: relative !important; }

._pos_absolute {
  position: absolute !important; }

._block {
  display: block !important; }

@media only screen and (max-width: 1170px) {
  ._block_tb {
    display: block !important; } }

@media only screen and (max-width: 640px) {
  ._block_mb {
    display: block !important; } }

._flex {
  display: flex !important; }

@media only screen and (max-width: 1170px) {
  ._flex_tb {
    display: flex !important; } }

@media only screen and (max-width: 640px) {
  ._flex_mb {
    display: flex !important; } }

._inline_block {
  display: inline-block !important; }

@media only screen and (max-width: 1170px) {
  ._inline_block_tb {
    display: inline-block !important; } }

@media only screen and (max-width: 640px) {
  ._inline_block_mb {
    display: inline-block !important; } }

._inline {
  display: inline !important; }

@media only screen and (max-width: 1170px) {
  ._inline_tb {
    display: inline !important; } }

@media only screen and (max-width: 640px) {
  ._inline_mb {
    display: inline !important; } }

._only_tb {
  display: none !important; }
  @media only screen and (max-width: 1170px) {
    ._only_tb {
      display: block !important; } }

._only_inline_block_tb {
  display: none !important; }
  @media only screen and (max-width: 1170px) {
    ._only_inline_block_tb {
      display: inline-block !important; } }

._only_flex_tb {
  display: none !important; }
  @media only screen and (max-width: 1170px) {
    ._only_flex_tb {
      display: flex !important; } }

._only_inline_tb {
  display: none !important; }
  @media only screen and (max-width: 1170px) {
    ._only_inline_tb {
      display: inline !important; } }

._only_mb {
  display: none !important; }
  @media only screen and (max-width: 640px) {
    ._only_mb {
      display: block !important; } }

._only_inline_block_mb {
  display: none !important; }
  @media only screen and (max-width: 640px) {
    ._only_inline_block_mb {
      display: inline-block !important; } }

._only_flex_mb {
  display: none !important; }
  @media only screen and (max-width: 640px) {
    ._only_flex_mb {
      display: flex !important; } }

._only_inline_mb {
  display: none !important; }
  @media only screen and (max-width: 640px) {
    ._only_inline_mb {
      display: inline !important; } }

._none {
  display: none !important; }

@media only screen and (max-width: 1170px) {
  ._none_tb {
    display: none !important; } }

@media only screen and (max-width: 640px) {
  ._none_mb {
    display: none !important; } }

._bind {
  position: relative !important; }

._unbind {
  position: static !important; }

._flex_horiz_center {
  justify-content: center; }

._full_w {
  width: 100% !important; }

._full_h {
  height: 100% !important; }

._full_h_vh {
  height: 100vh !important; }

._min_h_auto {
  min-height: auto !important; }

._min_h_full_vh {
  min-height: 100vh !important; }

._hidden {
  overflow: hidden !important; }

._overflow_auto {
  overflow: auto !important; }

._block_center {
  margin-left: auto !important;
  margin-right: auto !important; }

._block_vert_center {
  margin-top: auto !important;
  margin-bottom: auto !important; }

._letter_spacing_inherit {
  letter-spacing: inherit; }

._text_center {
  text-align: center !important; }

._text_left {
  text-align: left !important; }

._text_right {
  text-align: right !important; }

._text_capitalize {
  text-transform: capitalize !important; }

._text_uppercase {
  text-transform: uppercase !important; }

._text_ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal; }

._nowrap {
  white-space: nowrap; }

._cur_p {
  cursor: pointer !important; }

._events_none {
  pointer-events: none !important; }

/* mixins */
/* base */
.-ol-btn {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  border-style: solid;
  background-image: none;
  font-weight: 700;
  letter-spacing: 0.06em;
  text-align: center;
  cursor: pointer;
  touch-action: manipulation;
  transition: all .2s;
  height: auto;
  min-height: auto;
  border-radius: 4px;
  border-width: 1px;
  padding: 7px 25px;
  font-size: 1.6rem;
  line-height: 24px;
  border-color: #1251F2;
  background: #1251F2;
  color: #fff;
  transition: all .2s; }
  .-ol-btn:hover {
    border-color: #0c47de;
    background: #0c47de;
    color: #fff; }
  .-ol-btn:active {
    border-color: #0b40c6;
    background: #0b40c6;
    color: #fff; }
  .-ol-btn_default {
    border-color: #fff;
    background: #fff;
    color: #1251F2;
    transition: all .2s; }
    .-ol-btn_default:hover {
      border-color: #f2f2f2;
      background: #f2f2f2;
      color: #1251F2; }
    .-ol-btn_default:active {
      border-color: #e6e6e6;
      background: #e6e6e6;
      color: #1251F2; }
  .-ol-btn_success {
    border-color: #72DA0B;
    background: #72DA0B;
    color: #fff;
    transition: all .2s; }
    .-ol-btn_success:hover {
      border-color: #65c20a;
      background: #65c20a;
      color: #fff; }
    .-ol-btn_success:active {
      border-color: #59a909;
      background: #59a909;
      color: #fff; }
  .-ol-btn_info {
    border-color: #30D0F3;
    background: #30D0F3;
    color: #fff;
    transition: all .2s; }
    .-ol-btn_info:hover {
      border-color: #18cbf2;
      background: #18cbf2;
      color: #fff; }
    .-ol-btn_info:active {
      border-color: #0dbce3;
      background: #0dbce3;
      color: #fff; }
  .-ol-btn_warning {
    border-color: #FFAF13;
    background: #FFAF13;
    color: #fff;
    transition: all .2s; }
    .-ol-btn_warning:hover {
      border-color: #f9a400;
      background: #f9a400;
      color: #fff; }
    .-ol-btn_warning:active {
      border-color: #df9300;
      background: #df9300;
      color: #fff; }
  .-ol-btn_danger {
    border-color: #F8144A;
    background: #F8144A;
    color: #fff;
    transition: all .2s; }
    .-ol-btn_danger:hover {
      border-color: #eb073d;
      background: #eb073d;
      color: #fff; }
    .-ol-btn_danger:active {
      border-color: #d30637;
      background: #d30637;
      color: #fff; }
  .-ol-btn_outline {
    border-color: #1251F2;
    background: transparent;
    color: #1251F2;
    transition: all .2s; }
    .-ol-btn_outline:hover {
      border-color: #0c47de;
      background: rgba(0, 0, 0, 0);
      color: #1251F2; }
    .-ol-btn_outline:active {
      border-color: #0b40c6;
      background: rgba(0, 0, 0, 0);
      color: #1251F2; }
  .-ol-btn[disabled] {
    border-color: #cccccc;
    background: #cccccc;
    color: #fff;
    transition: all .2s;
    cursor: not-allowed;
    pointer-events: none; }
    .-ol-btn[disabled]:hover {
      border-color: #cccccc;
      background: #cccccc;
      color: #fff; }
    .-ol-btn[disabled]:active {
      border-color: #cccccc;
      background: #cccccc;
      color: #fff; }
  .-ol-btn_link {
    width: auto;
    height: auto;
    border: 0;
    border-radius: 0;
    padding: 0;
    border-color: transparent;
    border-bottom: 1px solid transparent;
    color: #1251F2; }
    .-ol-btn_link:hover {
      border-bottom-color: fadeout(#1251F2, 20%);
      color: #1251F2; }
  .-ol-btn_lg {
    height: auto;
    min-height: auto;
    border-radius: 6px;
    border-width: 2px;
    padding: 8px 30px;
    font-size: 2rem;
    line-height: 30px; }
  .-ol-btn_sm {
    height: auto;
    min-height: auto;
    border-radius: 3px;
    border-width: 1px;
    padding: 6.5px 20px;
    font-size: 1.4rem;
    line-height: 21px; }
  .-ol-btn_xs {
    height: auto;
    min-height: auto;
    border-radius: 3px;
    border-width: 1px;
    padding: 4.5px 15px;
    font-size: 1.4rem;
    line-height: 21px; }
  .-ol-btn_min_w_initial {
    min-width: 0 !important; }

.-ol-input,
.-ol-textarea {
  display: block;
  width: 100%;
  padding: 6px 11px;
  border: 1px solid #E2E7ED;
  border-radius: 2px;
  background: #fff;
  color: #000;
  font-size: 1.6rem;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-overflow: ellipsis; }
  .-ol-input::-webkit-input-placeholder,
  .-ol-textarea::-webkit-input-placeholder {
    opacity: 1;
    color: #B7BFCE;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input::-moz-placeholder,
  .-ol-textarea::-moz-placeholder {
    opacity: 1;
    color: #B7BFCE;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:-moz-placeholder,
  .-ol-textarea:-moz-placeholder {
    opacity: 1;
    color: #B7BFCE;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:-ms-input-placeholder,
  .-ol-textarea:-ms-input-placeholder {
    opacity: 1;
    color: #B7BFCE;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:hover::-webkit-input-placeholder,
  .-ol-textarea:hover::-webkit-input-placeholder {
    opacity: 1;
    color: #B7BFCE;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:hover::-moz-placeholder,
  .-ol-textarea:hover::-moz-placeholder {
    opacity: 1;
    color: #B7BFCE;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:hover:-moz-placeholder,
  .-ol-textarea:hover:-moz-placeholder {
    opacity: 1;
    color: #B7BFCE;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:hover:-ms-input-placeholder,
  .-ol-textarea:hover:-ms-input-placeholder {
    opacity: 1;
    color: #B7BFCE;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:focus::-webkit-input-placeholder,
  .-ol-textarea:focus::-webkit-input-placeholder {
    opacity: 1;
    color: transparent;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:focus::-moz-placeholder,
  .-ol-textarea:focus::-moz-placeholder {
    opacity: 1;
    color: transparent;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:focus:-moz-placeholder,
  .-ol-textarea:focus:-moz-placeholder {
    opacity: 1;
    color: transparent;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:focus:-ms-input-placeholder,
  .-ol-textarea:focus:-ms-input-placeholder {
    opacity: 1;
    color: transparent;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input[readonly], .-ol-input[disabled],
  .-ol-textarea[readonly],
  .-ol-textarea[disabled] {
    color: #B7BFCE;
    cursor: default; }

.-ol-input {
  height: 34px; }

.-ol-textarea {
  height: 74px;
  resize: vertical;
  overflow: auto; }

.-ol-input-file {
  position: relative;
  overflow: hidden;
  width: 100%; }
  .-ol-input-file input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    opacity: 0;
    cursor: pointer; }

.-ol-checkbox {
  position: relative;
  width: 14px;
  height: 14px; }
  .-ol-checkbox__input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    overflow: hidden;
    width: 100%;
    height: 100%; }
  .-ol-checkbox__trigger {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #E2E7ED;
    border-radius: 2px;
    background-color: #fff;
    cursor: pointer; }
  .-ol-checkbox__input:checked ~ .-ol-checkbox__trigger {
    border: 5px solid #1251F2;
    background-color: #fff; }
  .-ol-checkbox[disabled] {
    opacity: .55;
    cursor: default; }

.-ol-radio {
  position: relative;
  width: 14px;
  height: 14px; }
  .-ol-radio__input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    overflow: hidden;
    width: 100%;
    height: 100%; }
    .-ol-radio__input[disabled] {
      cursor: default; }
  .-ol-radio__trigger {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #E2E7ED;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer; }
  .-ol-radio__input:checked ~ .-ol-radio__trigger {
    border: 5px solid #1251F2;
    background-color: #fff; }
  .-ol-radio__input[disabled] ~ .-ol-radio__trigger {
    border-color: #E2E7ED;
    background-color: #E2E7ED; }

.-ol-label {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; }
  .-ol-label_last {
    margin-bottom: 0; }

.-ol-label-text {
  display: block;
  color: #000;
  font-size: 1.6rem;
  font-weight: 700; }

.-ol-input-wrap {
  position: relative; }
  .-ol-input-wrap_disabled {
    opacity: .6; }
    .-ol-input-wrap_disabled .-ol-input,
    .-ol-input-wrap_disabled .-ol-textarea {
      color: #B7BFCE; }
    .-ol-input-wrap_disabled label {
      cursor: auto; }
  .-ol-input-wrap_error .-ol-input,
  .-ol-input-wrap_error .-ol-textarea {
    border-color: #F8144A;
    color: #F8144A; }

.-ol-row-input {
  margin-bottom: 10px; }
  .-ol-row-input_last {
    margin-bottom: 0; }

@media only screen and (max-width: 640px) {
  .-ol-row-input-mb {
    margin-bottom: 10px; } }

.-ol-form-body {
  margin-bottom: 20px; }
  .-ol-form-body_last {
    margin-bottom: 0; }

.-ol-content h1,
.-ol-h1,
.-ol-content h2,
.-ol-h2,
.-ol-content h3,
.-ol-h3,
.-ol-content h4,
.-ol-h4,
.-ol-content h5,
.-ol-h5,
.-ol-content h6,
.-ol-h6 {
  color: #000;
  line-height: 1.35;
  font-weight: 400; }

.-ol-content h1,
.-ol-h1 {
  margin-bottom: 20px;
  font-size: 3.6rem; }

.-ol-content h2,
.-ol-h2 {
  margin-bottom: 15px;
  font-size: 3rem; }

.-ol-content h3,
.-ol-h3 {
  margin-bottom: 10px;
  font-size: 2.4rem; }

.-ol-content h4,
.-ol-h4 {
  margin-bottom: 7px;
  font-size: 1.8rem; }

.-ol-content h5,
.-ol-h5 {
  margin-bottom: 5px;
  font-size: 1.6rem; }

.-ol-content h6,
.-ol-h6 {
  margin-bottom: 5px;
  font-size: 1.2rem; }

.-ol-h-none {
  margin-bottom: 0;
  color: #000;
  font-family: "Inter", sans-serif !important;
  font-size: 1.6rem;
  line-height: 1.35;
  letter-spacing: 0.06em;
  font-weight: 400;
  word-spacing: 0;
  text-transform: none; }

.-ol-content a,
.-ol-link {
  display: inline;
  border-bottom: 1px solid transparent;
  color: #1251F2;
  line-height: inherit;
  letter-spacing: inherit;
  cursor: pointer;
  transition: all .2s; }
  .-ol-content a:hover,
  .-ol-link:hover {
    border-bottom-color: #0b40c6;
    color: #0b40c6; }

.-ol-content b,
.-ol-b,
.-ol-content strong,
.-ol-strong {
  font-weight: bold;
  letter-spacing: inherit; }

.-ol-content i,
.-ol-i,
.-ol-content em,
.-ol-em {
  font-style: italic;
  letter-spacing: inherit; }

.-ol-content sub,
.-ol-sub,
.-ol-content sup,
.-ol-sup {
  font-size: .6em; }

.-ol-content sub,
.-ol-sub {
  vertical-align: bottom; }

.-ol-content sup,
.-ol-sup {
  vertical-align: top; }

.i-link::after, .b-content h2 a::after, .b-content h3 a::after,
.i-info::after,
.i-warning::after,
.i-tick::after,
.b-icon_tick::after,
.i-planhat-horizontal::after,
.b-header-mb__logo::after,
.i-folder-open::after,
.b-sidebar__subnav-link._active::after,
.b-sidebar__subnav-link._open::after,
.i-folder::after,
.b-sidebar__subnav-link::after {
  font-family: "icomoon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  letter-spacing: 0;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga'; }

.i-link::after, .b-content h2 a::after, .b-content h3 a::after {
  content: '\e906'; }

.i-info::after {
  content: '\e904'; }

.i-warning::after {
  content: '\e905'; }


.i-tick::after,
.b-icon_tick::after {
  content: '\e903'; }


.i-planhat-horizontal::after,
.b-header-mb__logo::after {
  content: '\e900'; }


.i-folder::after,
.b-sidebar__subnav-link::after {
  content: '\e902'; }


.i-folder-open::after,
.b-sidebar__subnav-link._active::after,
.b-sidebar__subnav-link._open::after {
  content: '\e901'; }

/* snippets */
._bg_c_primary {
  background-color: #1251F2 !important; }

._bg_c_success {
  background-color: #72DA0B !important; }

._bg_c_warning {
  background-color: #FFAF13 !important; }

._bg_c_warning_2 {
  background-color: #FFCE21 !important; }

._bg_c_danger {
  background-color: #F8144A !important; }

._c_success {
  color: #72DA0B !important; }

/* project */
pre code.hljs {
  display: block;
  overflow-x: auto;
  margin: -7px 0 -6px;
  padding: 0;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: .02em; }

/* end baseline CSS */
.hljs {
  color: #f8f8f2; }

/* Base color: saturation 0 */
.hljs-subst {
  /* default */ }

/* purposely ignored */
.hljs-punctuation {
  color: #444a; }

.hljs-tag,
.hljs-subst {
  color: #f8f8f2; }

.hljs-tag .hljs-name,
.hljs-tag .hljs-attr {
  color: #444; }

.hljs-attribute,
.hljs-meta .hljs-keyword,
.hljs-doctag,
.hljs-name {
  font-weight: bold; }

/* User color: hue: 0 */
.hljs-template-tag {
  color: #880000; }

.hljs-section {
  font-weight: bold; }

.hljs-operator {
  color: #BC6060; }

/* Meta color: hue: 200 */
.hljs-meta .hljs-string {
  color: #4d99bf; }

/* Misc effects */
.hljs-emphasis {
  font-style: italic; }

.hljs-strong {
  font-weight: bold; }

.hljs-strong,
.hljs-emphasis {
  color: #a8a8a2; }

.hljs-bullet,
.hljs-quote,
.hljs-number,
.hljs-regexp,
.hljs-literal,
.hljs-link {
  color: #ae81ff; }

.hljs-code,
.hljs-title,
.hljs-section,
.hljs-selector-class {
  color: #a6e22e; }

.hljs-keyword,
.hljs-selector-tag,
.hljs-name,
.hljs-attr {
  color: #f92672; }

.hljs-symbol,
.hljs-attribute {
  color: #66d9ef; }

.hljs-params,
.hljs-title.class_,
.hljs-class .hljs-title {
  color: #f8f8f2; }

.hljs-string,
.hljs-type,
.hljs-built_in,
.hljs-selector-id,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-addition,
.hljs-variable,
.hljs-template-variable {
  color: #e6db74; }

.hljs-comment,
.hljs-deletion,
.hljs-meta {
  color: #72DA0B; }

.c-code-example_sticky {
  position: fixed;
  top: 40px;
  left: calc( 50% + 130px);
  z-index: 1;
  max-width: calc( 50% - 170px); }

.c-code-example__from-block {
  bottom: 100%;
  height: 40px; }

.c-code-example__to-block {
  bottom: 0; }

.l-cell {
  display: table;
  height: 100%;
  width: 100%;
  table-layout: fixed; }
  .l-cell__item {
    display: table-cell;
    vertical-align: top; }
  .l-cell__item_major {
    width: 100%; }
  .l-cell__item_middle {
    vertical-align: middle; }
  @media only screen and (max-width: 640px) {
    .l-cell__item_top_mb {
      vertical-align: top; } }

.l-code:not(:last-child) {
  border-bottom: 1px solid #E2E7ED; }

.l-code__inner {
  max-width: 2000px;
  margin: 0 auto;
  padding: 60px; }
  @media only screen and (min-width: 1751px) {
    .l-code__inner {
      max-width: 2500px; } }
  @media only screen and (max-width: 1750px) {
    .l-code__inner {
      padding: 55px; } }
  @media only screen and (max-width: 640px) {
    .l-code__inner {
      padding: 20px; } }

.l-code__content {
  display: flex;
  margin: -30px; }
  @media only screen and (max-width: 1750px) {
    .l-code__content {
      margin: -27.5px; } }
  @media only screen and (max-width: 1250px) {
    .l-code__content {
      flex-direction: column; } }
  @media only screen and (max-width: 640px) {
    .l-code__content {
      margin: -10px; } }

.l-code__item {
  width: 50%;
  padding: 30px; }
  @media only screen and (max-width: 1750px) {
    .l-code__item {
      padding: 27.5px; } }
  @media only screen and (max-width: 1250px) {
    .l-code__item {
      width: 100%;
      max-width: 640px;
      margin: 0 auto; } }
  @media only screen and (max-width: 640px) {
    .l-code__item {
      padding: 10px; } }
  .l-code__item_wide {
    width: 100%;
    max-width: 920px; }

.l-code__code {
  position: sticky;
  top: 0;
  margin-top: -19px;
  padding-top: 60px; }
  @media only screen and (max-width: 1750px) {
    .l-code__code {
      margin-top: 1px;
      padding-top: 55px; } }
  @media only screen and (max-width: 640px) {
    .l-code__code {
      margin: 0 -20px -20px;
      padding-top: 10px; } }

.l-content-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  transition: 0.2s filter; }
  ._modal_active .l-content-wrapper {
    filter: blur(3px); }
  .l-content-wrapper__item_major {
    flex-grow: 1; }
  .l-content-wrapper__item_shrink_none {
    flex-shrink: 0; }

.l-flex {
  display: flex;
  flex-wrap: wrap; }
  .l-flex_nowrap {
    flex-wrap: nowrap; }
  .l-flex_valign_center {
    align-items: center; }
  @media only screen and (max-width: 640px) {
    .l-flex_valign_center_mb {
      align-items: center; } }
  .l-flex_valign_center_top_mb {
    align-items: center; }
    @media only screen and (max-width: 640px) {
      .l-flex_valign_center_top_mb {
        align-items: flex-start; } }
  .l-flex_right {
    justify-content: flex-end; }
  .l-flex_center {
    justify-content: center; }
  .l-flex_space_between {
    justify-content: space-between; }
  .l-flex_space_around {
    justify-content: space-around; }
  .l-flex_col {
    flex-direction: column; }
  .l-flex > .l-flex__item_stretch {
    flex-grow: 1; }
  .l-flex > .l-flex__item_major {
    flex-shrink: 0; }
  @media only screen and (max-width: 1170px) {
    .l-flex > .l-flex__item_equal_none_tb {
      flex: 0 1 auto; } }
  .l-flex_equal {
    flex-wrap: nowrap; }
  .l-flex_equal > .l-flex__item {
    flex: 1 1 100%;
    overflow: hidden; }
  .l-flex_equal_with_width > .l-flex__item {
    flex-grow: 1;
    flex-basis: 0;
    width: 0; }

.l-indent {
  margin: -10px -15px; }
  .l-indent_sm {
    margin: -5px -10px; }
  .l-indent__item {
    padding: 10px 15px; }
  .l-indent_sm .l-indent__item {
    padding: 5px 10px; }

.l-inline {
  font-size: 0 !important;
  letter-spacing: 0 !important; }
  .l-inline__item {
    display: inline-block;
    vertical-align: top;
    font-size: 1.6rem;
    letter-spacing: 0.06em; }
  .l-inline__item_recovery {
    font-size: 1.6rem;
    letter-spacing: 0.06em; }

.l-justify {
  font-size: 0 !important;
  letter-spacing: 0 !important;
  text-align: justify;
  text-align-last: justify;
  text-justify: newspaper; }
  .l-justify::after {
    content: '';
    display: inline-block;
    width: 100%;
    visibility: hidden;
    overflow: hidden; }
  .l-justify__item {
    display: inline-block;
    vertical-align: top;
    font-size: 1.6rem;
    letter-spacing: 0.06em;
    text-align: left;
    text-align-last: auto;
    text-justify: auto; }
  .l-justify__item_recovery {
    font-size: 1.6rem;
    letter-spacing: 0.06em;
    text-align: left;
    text-align-last: auto;
    text-justify: auto; }

.l-nav {
  display: flex;
  height: 100vh;
  overflow: hidden; }
  @media only screen and (max-width: 640px) {
    .l-nav {
      position: relative;
      padding-top: 64px; } }
  .l-nav__item_nav {
    position: relative;
    z-index: 9;
    flex-shrink: 0;
    width: 260px; }
    @media only screen and (max-width: 640px) {
      .l-nav__item_nav {
        position: absolute;
        top: 0;
        left: -260px;
        height: 100%;
        transition: left .2s; } }
  .l-nav__item_content {
    position: relative;
    z-index: 1;
    flex-grow: 1;
    overflow: auto; }
    @media only screen and (max-width: 640px) {
      .l-nav__item_content {
        left: 0;
        flex-grow: 0;
        flex-shrink: 0;
        width: 100%;
        transition: left .2s; } }
  @media only screen and (max-width: 640px) {
    ._nav_active .l-nav__item_nav {
      left: 0; } }
  @media only screen and (max-width: 640px) {
    ._nav_active .l-nav__item_content {
      left: 260px; } }

.l-row {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%; }
  .l-row__item {
    display: table-row; }
  .l-row__item_major {
    height: 100%; }

.b-code {
  border-radius: 15px;
  background-color: #1D2129; }
  @media only screen and (max-width: 640px) {
    .b-code {
      border-radius: 0; } }
  .b-code__header {
    display: flex;
    justify-content: space-between;
    padding: 13px 30px;
    background: #262C37; }
    @media only screen and (max-width: 640px) {
      .b-code__header {
        padding: 13px 20px; } }
    .b-code__header:first-child {
      border-radius: 15px 15px 0 0; }
      @media only screen and (max-width: 640px) {
        .b-code__header:first-child {
          border-radius: 0; } }
  .b-code__header-tag {
    font-family: "Aeonik" !important;
    position: relative;
    height: calc( 100% + 26px);
    margin: -13px -37px -13px 0;
    padding: 2px 20px 0;
    background-color: #72DA0B;
    color: #fff;
    font-size: 14px;
    line-height: 34px;
    font-weight: 700;
    letter-spacing: .02em;
    text-align: center; }
    @media only screen and (max-width: 640px) {
      .b-code__header-tag {
        margin-right: -20px; } }
    .b-code__header-tag::before {
      content: '';
      position: absolute;
      bottom: 100%;
      right: 0;
      border: solid transparent;
      border-width: 7px 0 0 7px;
      border-left-color: #5EB209; }
      @media only screen and (max-width: 640px) {
        .b-code__header-tag::before {
          display: none; } }
    .b-code__header-tag::after {
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
      border: 18px solid transparent;
      border-right-width: 12px;
      border-right-color: #72DA0B;
      border-left-width: 0; }
  .b-code__header-text {
    margin: -6px 0;
    color: rgba(255, 255, 255, 0.35);
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    letter-spacing: .02em;
    font-family: "Aeonik" !important;
    text-transform: uppercase; }
  .b-code__tabs {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0 30px; }
  .b-code__tabs-inner {
    display: flex;
    margin: 0 -12px; }
  .b-code__tabs-item {
    padding: 0 12px; }
  .b-code__tabs-tab {
    position: relative;
    padding: 13px 0; }
    .b-code__tabs-tab::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(248, 20, 74, 0);
      transition: .2s all; }
    .b-code__tabs-tab_active::before {
      background-color: #F8144A; }
  .b-code__tabs-tab-text {
    display: block;
    margin: -6px 0;
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    line-height: 22px;
    letter-spacing: .02em;
    transition: .2s color; }
  .b-code__tabs-tab_active .b-code__tabs-tab-text,
  .b-code__tabs-tab:hover .b-code__tabs-tab-text {
    color: #fff; }
    .b-code__tabs-tab_active .b-code__tabs-tab-text_hightlight,
    .b-code__tabs-tab:hover .b-code__tabs-tab-text_hightlight {
      color: #72DA0B; }
  .b-code__body {
    padding: 25px 30px; }
    @media only screen and (max-width: 640px) {
      .b-code__body {
        padding: 20px 20px; } }

.b-content {
  display: flex;
  flex-direction: column;
  align-items: start; }
  .b-content h2 {
    margin: 53px 0 25px;
    color: #000;
    font-size: 28px;
    line-height: 32px;
    font-weight: 700;
    letter-spacing: .02em; }
    .b-content h2:first-child {
      margin-top: -7px; }
    .b-content h2:last-child {
      margin-bottom: -5px; }
    .b-content h2 + h3 {
      margin-top: 4px; }
    .b-content h2 a {
      position: relative;
      border: none;
      color: #000;
      font-family: "Aeonik" !important; }
      @media only screen and (max-width: 640px) {
        .b-content h2 a {
          padding-left: 30px; } }
      .b-content h2 a::after {
        position: absolute;
        top: calc( 50% + 1px);
        left: -30px;
        color: #B7BFCE;
        font-size: 18px;
        transform: translateY(-50%);
        transition: color .2s; }
        @media only screen and (max-width: 1750px) {
          .b-content h2 a::after {
            left: -25px; } }
        @media only screen and (max-width: 640px) {
          .b-content h2 a::after {
            left: 0; } }
      .b-content h2 a:hover {
        color: #1251F2; }
        .b-content h2 a:hover::after {
          color: rgba(18, 81, 242, 0.5); }
  .b-content__h2_tag {
    position: relative;
    top: -4px;
    margin-left: 12px;
    border-radius: 4px;
    padding: 3px 10px 5px;
    color: #fff;
    font-size: 15px;
    line-height: 26px;
    font-weight: 700;
    letter-spacing: .02em; }
  .b-content h3 {
    margin: 53px 0 25px;
    color: #000;
    font-family: "Aeonik" !important;
    font-size: 22px;
    line-height: 32px;
    font-weight: 700;
    letter-spacing: .02em; }
    .b-content h3:first-child {
      margin-top: -7px; }
    .b-content h3:last-child {
      margin-bottom: -5px; }
    .b-content h3 + h3 {
      margin-top: 4px; }
    .b-content h3 a {
      position: relative;
      border: none;
      color: #000; }
      .b-content h3 a::after {
        position: absolute;
        top: calc( 50% + 1px);
        left: -30px;
        color: #B7BFCE;
        font-size: 18px;
        transform: translateY(-50%);
        transition: color .2s; }
      .b-content h3 a:hover {
        color: #1251F2; }
        .b-content h3 a:hover::after {
          color: rgba(18, 81, 242, 0.5); }
  .b-content__h2_tag {
    position: relative;
    top: -4px;
    margin-left: 12px;
    border-radius: 4px;
    padding: 3px 10px 5px;
    color: #fff;
    font-size: 15px;
    line-height: 26px;
    font-weight: 700;
    letter-spacing: .02em; }
  .b-content p {
    margin: -6px 0 20px;
    color: #262C37;
    font-size: 14px;
    line-height: 24px; }
    .b-content p:last-child:not(.b-content__text-minor) {
      margin-bottom: -5px; }
    .b-content p + h2 {
      margin-top: 28px; }
    .b-content p + h3 {
      margin-top: 9px; }
  .b-content a {
    display: inline;
    border-bottom: 1px solid transparent;
    color: #1251F2;
    line-height: inherit;
    letter-spacing: inherit;
    cursor: pointer;
    transition: all .2s; }
    .b-content a:hover {
      border-bottom-color: #0b40c6;
      color: #0b40c6; }
  .b-content b {
    line-height: 0;
    color: #000;
    font-weight: 700; }
  .b-content i {
    font-style: italic; }
  .b-content ul,
  .b-content ol {
    margin-bottom: 25px; }
    .b-content ul:first-child,
    .b-content ol:first-child {
      margin-top: 0; }
    .b-content ul:last-child,
    .b-content ol:last-child {
      margin-bottom: 0; }
    .b-content ul + h2,
    .b-content ol + h2 {
      margin-top: 28px; }
    .b-content ul + h3,
    .b-content ol + h3 {
      margin-top: 9px; }
  .b-content ol {
    counter-reset: content-list; }
  .b-content li {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    padding-left: 22px; }
    .b-content li:last-child {
      margin-bottom: 0; }
  .b-content ul > li::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    border: 2px solid #1251F2; }
  .b-content ul.b-content__list_minor li::before {
    border-color: #67CDEF; }
  .b-content ol > li::before {
    counter-increment: content-list;
    content: counter(content-list) ".";
    position: absolute;
    top: 0;
    left: 0;
    margin: -6px 0 -5px;
    color: #262C37;
    font-size: 13px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: .02em; }
  .b-content img,
  .b-content video,
  .b-content iframe, .b-content__block,
  .b-content button, .b-content__note {
    margin-bottom: 25px; }
    .b-content img:last-child,
    .b-content video:last-child,
    .b-content iframe:last-child, .b-content__block:last-child,
    .b-content button:last-child, .b-content__note:last-child {
      margin-bottom: 0; }
    .b-content img + h2,
    .b-content video + h2,
    .b-content iframe + h2, .b-content__block + h2,
    .b-content button + h2, .b-content__note + h2 {
      margin-top: 28px; }
    .b-content img + h3,
    .b-content video + h3,
    .b-content iframe + h3, .b-content__block + h3,
    .b-content button + h3, .b-content__note + h3 {
      margin-top: 9px; }
  .b-content video,
  .b-content iframe, .b-content__block, .b-content__note {
    display: block;
    max-width: 100%; }
  .b-content table {
    width: 100%;
    min-width: 33vw;
    text-align: left; }
  .b-content tr {
    border-bottom: 1px solid #E2E7ED; }
  .b-content td,
  .b-content th {
    vertical-align: middle; }
    .b-content td:first-child,
    .b-content th:first-child {
      padding-left: 0; }
    .b-content td:last-child,
    .b-content th:last-child {
      padding-right: 0; }
  .b-content th {
    padding: 1px 5px 14px;
    color: #454a53;
    font-size: 10px;
    line-height: 14px;
    font-weight: 700;
    letter-spacing: .04em;
    text-transform: uppercase; }
  .b-content td {
    padding: 0 5px 2px;
    color: #454a53;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em; }
  .b-content tbody td {
    height: 39px; }
  .b-content code {
    display: block;
    margin-bottom: 25px;
    border-radius: 4px;
    padding: 6px 12px;
    background: #E2E7ED;
    color: #262C37;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: .02em; }
    .b-content code:last-child {
      margin-bottom: 0; }
    .b-content code + h2 {
      margin-top: 28px; }
    .b-content code + h3 {
      margin-top: 9px; }
  .b-content code.b-content__code_close {
    margin-top: -10px;
    margin-bottom: 15px; }
  .b-content__note {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 59px;
    border-left: 3px solid #1251F2;
    padding: 15px;
    background: rgba(18, 81, 242, 0.1); }
    .b-content__note_warning {
      border-color: #FFAF13;
      background: rgba(255, 175, 19, 0.1); }
    .b-content__note_danger {
      border-color: #F8144A;
      background: rgba(248, 20, 74, 0.1); }
    .b-content__note_success {
      border-color: #72DA0B;
      background: rgba(114, 218, 11, 0.1); }
    .b-content__note p,
    .b-content__note b {
      color: #1048D9; }
    .b-content__note p {
      font-weight: italic; }
    .b-content__note_warning p,
    .b-content__note_warning b {
      color: #E59905; }
    .b-content__note_danger p,
    .b-content__note_danger b {
      color: #DE073A; }
    .b-content__note_success p,
    .b-content__note_success b {
      color: #64BF0A; }

.b-header-mb {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  display: none;
  height: 64px;
  width: 100%;
  padding: 20px;
  background: #fff; }
  @media only screen and (max-width: 640px) {
    .b-header-mb {
      display: block; } }
  .b-header-mb__inner {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .b-header-mb__logo {
    display: flex;
    justify-content: center; }
    .b-header-mb__logo::after {
      font-size: 24px; }
  .b-header-mb__nav {
    position: relative;
    width: 18px;
    height: 18px;
    cursor: pointer; }
    .b-header-mb__nav::before, .b-header-mb__nav::after {
      content: '';
      position: absolute;
      height: 2px;
      border-radius: 1px;
      background: #1D2129; }
    .b-header-mb__nav::before {
      top: 8px;
      right: 0;
      width: 12px; }
    .b-header-mb__nav::after {
      top: 16px;
      left: 0;
      width: 15px; }
  .b-header-mb__nav-icon {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    background: #1D2129; }
    .b-header-mb__nav-icon::before {
      content: '';
      position: absolute;
      top: -10px;
      left: -10px;
      width: calc( 100% + 20px);
      height: calc( 100% + 20px); }

.b-icon_in_content_table {
  position: relative;
  top: 1px; }

.b-icon_tick {
  display: flex; }
  .b-icon_tick::after {
    font-size: 12px; }

.b-sidebar {
  padding: 20px;
  background: #121211;
  color: #fff;
  overflow-y: auto;
  overflow-x: hidden; }
  .b-sidebar__logo {
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 20px; }
  .b-sidebar__logo-img {
    display: flex;
    justify-content: start; }
    .b-sidebar__logo-img img {
      width: 45px; }
  .b-sidebar__nav {
    margin: 0 -20px; }
  .b-sidebar__nav-link {
    position: relative;
    display: block;
    padding: 15px 20px; }
    .b-sidebar__nav-link::before {
      content: '';
      position: absolute;
      top: 0;
      right: calc( 100% + 15px);
      width: 100%;
      height: 100%;
      background-color: #000000;
      transition: right .2s; }
    .b-sidebar__nav-link._active::before {
      right: 0; }
      @media only screen and (max-width: 640px) {
        .b-sidebar__nav-link._active::before {
          right: calc( 100% + 15px); } }
    @media only screen and (max-width: 640px) {
      ._nav_active .b-sidebar__nav-link._active::before {
        right: 0; } }
  .b-sidebar__nav-link-text {
    position: relative;
    z-index: 1;
    font-family: "Aeonik" !important;
    margin: -6px 0;
    color: #94948e;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    letter-spacing: .04em;
    transition: .2s color; }
  .b-sidebar__nav-link._active .b-sidebar__nav-link-text,
  .b-sidebar__nav-link:hover .b-sidebar__nav-link-text {
    color: #fff; }
  .b-sidebar__subnav {
    display: none;
    padding: 4px 0; }
  .b-sidebar__nav-link._active ~ .b-sidebar__subnav {
    display: block; }
  .b-sidebar__subnav-link {
    position: relative;
    display: block;
    padding: 11px 20px 11px 65px; }
    .b-sidebar__subnav-link::before {
      content: '';
      position: absolute;
      top: 14px;
      left: 48px;
      border: 3px solid transparent;
      border-bottom-width: 0;
      border-top-color: rgba(255, 255, 255, 0.4);
      transform: rotateZ(-90deg);
      transition: .2s all; }
    .b-sidebar__subnav-link:not(.b-sidebar__subnav-link._active):hover::before {
      border-top-color: #fff; }
    .b-sidebar__subnav-link._active::before, .b-sidebar__subnav-link._open::before {
      transform: rotateZ(0deg); }
    .b-sidebar__subnav-link._open::before {
      border-top-color: rgba(255, 255, 255, 0.4); }
    .b-sidebar__subnav-link._active::before {
      border-top-color: #FFCE21; }
    .b-sidebar__subnav-link::after {
      position: absolute;
      top: 8px;
      left: 21px;
      color: rgba(255, 255, 255, 0.4);
      font-size: 14px;
      transition: .2s color; }
    .b-sidebar__subnav-link:not(.b-sidebar__subnav-link._active):hover::after {
      color: #fff; }
    .b-sidebar__subnav-link._active::after {
      left: 19px;
      color: #FFCE21; }
  .b-sidebar__subnav-link-text {
    margin: -6px 0 -4px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 13px;
    line-height: 19px;
    font-weight: 600;
    letter-spacing: .04em;
    transition: .2s color; }
  .b-sidebar__subnav-link._active .b-sidebar__subnav-link-text,
  .b-sidebar__subnav-link:hover .b-sidebar__subnav-link-text {
    color: #fff; }
  .b-sidebar__subnav-inner {
    display: none; }
  .b-sidebar__subnav-link._active ~ .b-sidebar__subnav-inner {
    display: block; }
  .b-sidebar__subnav-inner-link {
    position: relative;
    display: block;
    padding: 11px 20px 11px 65px; }
  .b-sidebar__subnav-inner-link-subtext {
    position: absolute;
    top: 13px;
    left: 18px;
    margin: -4px 0;
    color: rgba(255, 255, 255, 0.25);
    font-size: 10px;
    line-height: 15px;
    font-weight: 600;
    letter-spacing: .02em;
    text-transform: uppercase;
    transition: .2s color; }
  .b-sidebar__subnav-inner-link:hover .b-sidebar__subnav-inner-link-subtext {
    color: #fff; }
  .b-sidebar__subnav-inner-link._active .b-sidebar__subnav-inner-link-subtext {
    color: #FFCE21; }
  .b-sidebar__subnav-inner-link-text {
    margin: -5px 0 -4px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    line-height: 18px;
    letter-spacing: .02em;
    transition: .2s color; }
  .b-sidebar__subnav-inner-link._active .b-sidebar__subnav-inner-link-text,
  .b-sidebar__subnav-inner-link:hover .b-sidebar__subnav-inner-link-text {
    color: #fff; }
