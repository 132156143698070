/*----------- main -----------*/

$black:							#000;
$grey-base:						$black;
$grey-darker:					#1D2129; // 16%
$grey-dark:						#262C37; // 22%
$grey:							#454a53; // 33%
$grey-light:					#B7BFCE; // 81%
$grey-lighter:					#E2E7ED; // 93%
$grey-lightest:					lighten( $grey-base, 98% ); // #fafafa
$white:							#fff;

$brand-primary:					#1251F2; // blue
$brand-success:					#72DA0B; // green
$brand-info:					#30D0F3; // cyan
$brand-warning:					#FFAF13; // orange
$brand-warning-2:				#FFCE21; // yellow
$brand-danger:					#F8144A; // red

/*----------- main (end) -----------*/





/*----------- typography -----------*/

$c-text-base:					$black;
$c-h:							$black;

/*----------- typography (end) -----------*/





/*----------- common -----------*/

$bg_c-body:						$white;
$brd_c:							$grey-lighter;

$b_sh_c:						$grey-darker;

/*----------- common (end) -----------*/





/*----------- link -----------*/

$c-link:							$brand-primary;
$brd_c-link:						transparent;
$c-link_hover:						darken( $c-link, 10% );
$brd_c-link_hover:					$c-link_hover;

/*----------- link (end) -----------*/





/*----------- form -----------*/

$c-form-error: 						$brand-danger;
$c-label: 							$c-text-base;

$bg-input: 							$white;
$brd_c-input: 						$brd_c;

$c-input: 							$c-text-base;
$c-input_disabled:					$grey-light;

$c-placeholder:						$grey-light;

$brd_c-form-error:					$c-form-error;

$bg-checkbox: 						$white;
$brd_c-checkbox: 					$brd_c;
$bg-checkbox_checked:				$white;
$brd_c-checkbox_checked:			$brand-primary;

$bg-radio: 							$white;
$brd_c-radio: 						$grey-lighter;
$bg-radio_checked:					$white;
$brd_c-radio_checked:				$brand-primary;

/*----------- form (end) -----------*/





/*----------- table -----------*/

$table_even:						darken( $white, 5% );
$table_odd:							darken( $white, 5% );

$table_hr:							darken( $white, 8% );

/*----------- table (end) -----------*/





/*----------- button -----------*/

$c-btn-default:						$brand-primary;
$c-btn-filled:						$white;
$c-btn-disabled:					$white;
$c-btn-outline:						$brand-primary;

$c-btn-link:						$c-link;
$c-btn-link_hover:					$c-btn-link;

$bg_c-btn-default:					$white;
$bg_c-btn-primary:					$brand-primary;
$bg_c-btn-success:					$brand-success;
$bg_c-btn-info:						$brand-info;
$bg_c-btn-warning:					$brand-warning;
$bg_c-btn-danger:					$brand-danger;
$bg_c-btn-disabled:					darken( $white, 20% );
$bg_c-btn-outline:					transparent;

$brd-c-btn-link:					transparent;
$brd-c-btn-link_hover:				fadeout( $c-btn-link, 20% );

/*----------- button (end) -----------*/
