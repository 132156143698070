._bg_c_primary {
	background-color: $brand-primary !important; }

._bg_c_success {
	background-color: $brand-success !important; }

._bg_c_warning {
	background-color: $brand-warning !important; }

._bg_c_warning_2 {
	background-color: $brand-warning-2 !important; }

._bg_c_danger {
	background-color: $brand-danger !important; }

._c_success {
	color: $brand-success !important; }
