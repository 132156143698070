.b-content {
	$m_t-h2:				60px;
	$m_t-h2_inner:			7px;
	$m_b-h2:				30px;
	$m_b-h2_inner:			5px;

	$m_t-h3:				40px;
	$m_t-h3_inner:			6px;
	$m_b-h3:				25px;
	$m_b-h3_inner:			14px;

	$m_t-p:					25px;
	$m_t-p_inner:			6px;
	$m_b-p:					25px;
	$m_b-p_inner:			5px;

	$m_t-ul:				25px;
	$m_b-ul:				25px;

	$m_t-img:				25px;
	$m_b-img:				25px;

	$m_t-code:				25px;
	$m_b-code:				25px;

	$m_b-blockquote:		50px;

	display: flex;
	flex-direction: column;
	align-items: start; // to don't stretch images and other tags if it less than 100%

	h2 {
		margin: ( $m_t-h2 - $m_t-h2_inner ) 0 ( $m_b-h2 - $m_b-h2_inner );

		color: $black;
		font-size: 28px;
		line-height: 32px;
		font-weight: 700;
		letter-spacing: .02em;

		&:first-child {
			margin-top: -$m_t-h2_inner; }

		&:last-child {
			margin-bottom: -$m_b-h2_inner; }

		+ h3 {

			@if $m_t-h3 > $m_b-h2 {
				margin-top: ( $m_t-h3 - $m_t-h3_inner - $m_b-h2_inner ) - ( $m_b-h2 - $m_b-h2_inner ); } }

		+ p {

			@if $m_t-p > $m_b-h2 {
				margin-top: ( $m_t-p - $m_t-p_inner - $m_b-h2_inner ) - ( $m_b-h2 - $m_b-h2_inner ); } }

		+ img,
		+ video,
		+ iframe,
		+ .b-content__block,
		+ button,
		+ &__note {

			@if $m_t-img > $m_b-h2 {
				margin-top: ( $m_t-img - $m_b-h2_inner ) - ( $m_b-h2 - $m_b-h2_inner ); } }

		+ ul,
		+ ol {

			@if $m_t-ul > $m_b-h2 {
				margin-top: ( $m_t-ul - $m_b-h2_inner ) - ( $m_b-h2 - $m_b-h2_inner ); } }

		+ code {

			@if $m_t-code > $m_b-h2 {
				margin-top: ( $m_t-code - $m_b-h2_inner ) - ( $m_b-h2 - $m_b-h2_inner ); } }

		a {
			position: relative;
			border: none;
			color: $black;
			font-family: $font-family-header;

			@extend .i-link;

			@include md-mobile {
				padding-left: 30px; }

			&::after {
				position: absolute;
				top: calc( 50% + 1px );
				left: -30px;

				color: $grey-light;
				font-size: 18px;

				transform: translateY( -50% );

				transition: color .2s;

				@include md-desk_lg {
					left: -25px; }

				@include md-mobile {
					left: 0; } }

			&:hover {
				color: $brand-primary;

				&::after {
					color: rgba( $brand-primary, .5 ); } } } }

	&__h2 {

		&_tag {
			position: relative;
			top: -4px;

			margin-left: 12px;
			border-radius: 4px;
			padding: 3px 10px 5px;

			color: $white;
			font-size: 15px;
			line-height: 26px;
			font-weight: 700;
			letter-spacing: .02em; } }

	h3 {
		margin: ( $m_t-h2 - $m_t-h2_inner ) 0 ( $m_b-h2 - $m_b-h2_inner );

		color: $black;
		font-family: $font-family-header;
		font-size: 22px;
		line-height: 32px;
		font-weight: 700;
		letter-spacing: .02em;

		&:first-child {
			margin-top: -$m_t-h2_inner; }

		&:last-child {
			margin-bottom: -$m_b-h2_inner; }

		+ h3 {

			@if $m_t-h3 > $m_b-h2 {
				margin-top: ( $m_t-h3 - $m_t-h3_inner - $m_b-h2_inner ) - ( $m_b-h2 - $m_b-h2_inner ); } }

		+ p {

			@if $m_t-p > $m_b-h2 {
				margin-top: ( $m_t-p - $m_t-p_inner - $m_b-h2_inner ) - ( $m_b-h2 - $m_b-h2_inner ); } }

		+ img,
		+ video,
		+ iframe,
		+ .b-content__block,
		+ button,
		+ &__note {

			@if $m_t-img > $m_b-h2 {
				margin-top: ( $m_t-img - $m_b-h2_inner ) - ( $m_b-h2 - $m_b-h2_inner ); } }

		+ ul,
		+ ol {

			@if $m_t-ul > $m_b-h2 {
				margin-top: ( $m_t-ul - $m_b-h2_inner ) - ( $m_b-h2 - $m_b-h2_inner ); } }

		+ code {

			@if $m_t-code > $m_b-h2 {
				margin-top: ( $m_t-code - $m_b-h2_inner ) - ( $m_b-h2 - $m_b-h2_inner ); } }

		a {
			position: relative;
			border: none;
			color: $black;

			@extend .i-link;

			&::after {
				position: absolute;
				top: calc( 50% + 1px );
				left: -30px;

				color: $grey-light;
				font-size: 18px;

				transform: translateY( -50% );

				transition: color .2s; }

			&:hover {
				color: $brand-primary;

				&::after {
					color: rgba( $brand-primary, .5 ); } } } }

	&__h2 {

		&_tag {
			position: relative;
			top: -4px;

			margin-left: 12px;
			border-radius: 4px;
			padding: 3px 10px 5px;

			color: $white;
			font-size: 15px;
			line-height: 26px;
			font-weight: 700;
			letter-spacing: .02em; } }

	p {
		margin: -$m_t-p_inner 0 ( $m_b-p - $m_b-p_inner );

		color: $grey-dark;
		font-size: 14px;
		line-height: 24px;


		&:last-child:not( .b-content__text-minor ) {
			margin-bottom: -$m_b-p_inner; }

		+ h2 {

			@if $m_t-h2 > $m_b-p {
				margin-top: ( $m_t-h2 - $m_t-h2_inner - $m_b-p_inner ) - ( $m_b-p - $m_b-p_inner ); } }

		+ h3 {

			@if $m_t-h3 > $m_b-p {
				margin-top: ( $m_t-h3 - $m_t-h3_inner - $m_b-p_inner ) - ( $m_b-p - $m_b-p_inner ); } }

		+ img,
		+ video,
		+ iframe,
		+ .b-content__block,
		+ button,
		+ &__note {

			@if $m_t-img > $m_b-p {
				margin-top: ( $m_t-img - $m_b-p_inner ) - ( $m_b-p - $m_b-p_inner ); } }

		+ ul,
		+ ol {

			@if $m_t-ul > $m_b-p {
				margin-top: $m_t-ul - $m_b-p; } }

		+ code {

			@if $m_t-code > $m_b-p {
				margin-top: $m_t-code - $m_b-p; } } }

	a {

		@include link-variant; }

	b {
		line-height: 0; // to fix bold alignment
		color: $black;
		font-weight: 700; }

	i {
		font-style: italic; }

	ul,
	ol {
		margin-bottom: $m_b-ul;

		&:first-child {
			margin-top: 0; }

		&:last-child {
			margin-bottom: 0; }

		+ h2 {

			@if $m_t-h2 > $m_b-ul {
				margin-top: ( $m_t-h2 - $m_t-h2_inner ) - $m_b-ul; } }

		+ h3 {

			@if $m_t-h3 > $m_b-ul {
				margin-top: ( $m_t-h3 - $m_t-h3_inner ) - $m_b-ul; } }

		+ p {

			@if $m_t-p > $m_b-ul {
				margin-top: ( $m_t-p - $m_t-p_inner ) - $m_b-ul; } }

		+ img,
		+ video,
		+ iframe,
		+ .b-content__block,
		+ button,
		+ &__note {

			@if $m_t-img > $m_b-ul {
				margin-top: ( $m_t-img - $m_b-p_inner ) - ( $m_b-p - $m_b-p_inner ); } }

		+ code {

			@if $m_t-code > $m_b-ul {
				margin-top: ( $m_t-code - $m_b-p_inner ) - ( $m_b-p - $m_b-p_inner ); } } }

	ol {
		counter-reset: content-list; }

	li {
		position: relative;

		display: flex;
		flex-direction: column;

		margin-bottom: 30px;
		padding-left: 22px;

		&:last-child {
			margin-bottom: 0; } }

	ul > li {

		&::before {
			$d: 9px;

			content: '';

			position: absolute;
			top: 0;
			left: 0;

			width: $d;
			height: $d;

			border-radius: 50%;

			border: 2px solid $brand-primary; } }

	& ul#{&}__list_minor li::before {
		border-color: #67CDEF; }

	ol > li {

		&::before {
			counter-increment: content-list;
			content: counter( content-list ) '.';

			position: absolute;
			top: 0;
			left: 0;

			margin: ( -$m_t-p_inner ) 0 ( -$m_b-p_inner );

			color: $grey-dark;
			font-size: 13px;
			line-height: 20px;
			font-weight: 700;
			letter-spacing: .02em; } }

	img,
	video,
	iframe,
	&__block,
	button,
	&__note {
		margin-bottom: $m_b-img;

		&:last-child {
			margin-bottom: 0; }

		+ h2 {

			@if $m_t-h2 > $m_b-img {
				margin-top: ( $m_t-h2 - $m_t-h2_inner ) - $m_b-img; } }

		+ h3 {

			@if $m_t-h3 > $m_b-img {
				margin-top: ( $m_t-h3 - $m_t-h3_inner ) - $m_b-img; } }

		+ p {

			@if $m_t-p > $m_b-img {
				margin-top: ( $m_t-p - $m_t-p_inner ) - $m_b-img; } }

		+ ul,
		+ ol {

			@if $m_t-ul > $m_b-img {
				margin-top: $m_t-ul - $m_b-img; } }

		+ code {

			@if $m_t-code > $m_b-img {
				margin-top: $m_t-code - $m_b-img; } } }

	video,
	iframe,
	&__block,
	&__note {
		display: block;
		max-width: 100%; }

	table {
		width: 100%;
		min-width: 33vw;
		text-align: left; }

	tr {
		border-bottom: $brd_w solid $brd_c; }

	td,
	th {
		vertical-align: middle;

		&:first-child {
			padding-left: 0; }

		&:last-child {
			padding-right: 0; } }

	th {
		padding: 1px $p_horiz-td 14px;

		color: $grey;
		font-size: 10px;
		line-height: 14px;
		font-weight: 700;
		letter-spacing: .04em;

		text-transform: uppercase; }

	td {
		padding: 0 5px 2px;

		color: $grey;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.02em; }

	tbody {

		td {
			height: 39px; } }

	code {
		display: block; // to stretch background

		margin-bottom: $m_b-code;
		border-radius: 4px;
		padding: 6px 12px;

		background: $grey-lighter;

		color: $grey-dark;
		font-size: 14px;
		line-height: 22px;
		letter-spacing: .02em;

		&:last-child {
			margin-bottom: 0; }

		+ h2 {

			@if $m_t-h2 > $m_b-code {
				margin-top: ( $m_t-h2 - $m_t-h2_inner ) - $m_b-code; } }

		+ h3 {

			@if $m_t-h3 > $m_b-code {
				margin-top: ( $m_t-h3 - $m_t-h3_inner ) - $m_b-code; } }

		+ p {

			@if $m_t-p > $m_b-code {
				margin-top: ( $m_t-p - $m_t-p_inner ) - $m_b-code; } }

		+ ul,
		+ ol {

			@if $m_t-ul > $m_b-code {
				margin-top: $m_t-ul - $m_b-code; } }

		+ img,
		+ video,
		+ iframe,
		+ .b-content__block,
		+ button,
		+ &__note {

			@if $m_t-img > $m_b-code {
				margin-top: $m_t-img - $m_b-code; } } }

	code#{&}__code_close {
		$m_vert: 10px;

		margin-top: -$m_vert;
		margin-bottom: $m_b-code - $m_vert; }

	&__note {
		display: flex;
		align-items: center;

		width: 100%;
		min-height: 59px;

		border-left: 3px solid $brand-primary;
		padding: 15px;

		background: rgba( $brand-primary, .1 );

		&_warning {
			border-color: $brand-warning;
			background: rgba( $brand-warning, .1 ); }

		&_danger {
			border-color: $brand-danger;
			background: rgba( $brand-danger, .1 ); }

		&_success {
			border-color: $brand-success;
			background: rgba( $brand-success, .1 ); }

		p,
		b {
			color: #1048D9; }

		p {
			font-weight: italic; }

		&_warning p,
		&_warning b {
			color: #E59905; }

		&_danger p,
		&_danger b {
			color: #DE073A; }

		&_success p,
		&_success b {
			color: #64BF0A; } } }



