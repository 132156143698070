.c-code-example {
	$p_vert: 40px;

	&_sticky {
		position: fixed;
		top: $p_vert;
		left: calc( 50% + 130px );
		z-index: 1;

		max-width: calc( 50% - 170px ); }

	&__from-block {
		bottom: 100%;
		height: $p_vert; }

	&__to-block {
		bottom: 0; } }
