@mixin font-sprite_element( $font-family: 'icomoon' ) {

	font-family: $font-family;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	line-height: 1;
	letter-spacing: 0;

	text-transform: none;
	speak: none;

	// Better Font Rendering:

	// Support for all WebKit browsers
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: greyscale;
	// Support for Safari and Chrome
	text-rendering: optimizeLegibility;

	// Support for Firefox
	-moz-osx-font-smoothing: grayscale;

	// Support for IE
	font-feature-settings: 'liga'; }

@mixin font-sprite( $element: '::after' ) {

	&#{$element} {

		@include font-sprite_element; } }

@mixin font-sprite-all_element( $size, $top: 0, $left: 0, $color: $white ) {

	// write position: relative yourself in the code,
	// because you can have pos-a or pos-f

	position: absolute;
	top: $top;
	left: $left;

	color: $color;
	font-size: $size;

	@include font-sprite_element; }

@mixin font-sprite-all( $content, $size, $top: 0, $left: 0, $color: $white, $element: '::after' ) {

	// write position: relative yourself in the code,
	// because you can have pos-a or pos-f

	&#{$element} {
		content: $content;

		@include font-sprite-all_element( $size, $top: 0, $left: 0, $color: $white ); } }

@mixin sprite-all( $bg_p, $sprite: $sprite ) {

	background-image: $sprite;
	background-repeat: no-repeat;
	background-position: $bg_p; }

@mixin sprite( $sprite: $sprite ) {

	background-image: $sprite;
	background-repeat: no-repeat; }
